import classnames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import Card from './card';

const useStyles = createUseStyles({
  board: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

const MarketsBoardComponent = () => {
  const classes = useStyles();
  const trendings = useSelector(_ => _.trendings);
  const { currency } = useSelector(_ => _.settings);
  return (
    <div className={classnames(classes.board, 'is-relative shadow mt12')}>
      {trendings.map(coin => (
        <Card key={coin.id} coin={coin} currency={currency} />
      ))}
    </div>
  );
};

MarketsBoardComponent.displayName = 'MarketsBoardComponent';

export default MarketsBoardComponent;
