import { IoWallet as PortfolioIcon } from 'react-icons/io5';

import PageComponent from './page';

const route = {
  Component: PageComponent,
  exact: true,
  icon: PortfolioIcon,
  iconSize: 32,
  id: PageComponent.displayName,
  order: 2,
  path: '/portfolio',
};

export default route;
