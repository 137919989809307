import { useCallback, useEffect, useState } from 'react';

export const useSorter = (entities, options) => {
  const [sort, setSort] = useState({
    desc: options.desc,
    key: options.filter.key,
    reverse: options.filter.ordering === 'asc',
    toggable: options.toggable,
  });
  const [sorted, setSorted] = useState([]);

  const sortEntities = useCallback(
    items => {
      const next = items
        .sort((a, b) => {
          if (a[sort.key] > b[sort.key]) return sort.reverse ? -1 : 1;
          if (a[sort.key] < b[sort.key]) return sort.reverse ? 1 : -1;
          return 0;
        })
        .reduce((acc, obj) => {
          if (sort.desc) return [...acc, { ...obj }];
          return [{ ...obj }, ...acc];
        }, []);
      setSorted(next);
    },
    [sort]
  );

  const sortHandler = useCallback(
    filter => {
      const reverse = filter.ordering === 'asc';
      const active = sort.key === filter.key;
      const order = !options.toggable || !active ? true : !sort.desc;
      setSort({ desc: order, key: filter.key, reverse });
    },
    [options.toggable, sort.desc, sort.key]
  );

  useEffect(() => {
    sortEntities(entities);
  }, [entities, sortEntities]);

  return {
    sort,
    sortHandler,
    sorted,
  };
};

export default useSorter;
