//  STORAGE ENGINES
// https://github.com/rt2zz/redux-persist#storage-engines
import storage from 'redux-persist/lib/storage';

import { APP_NAME, APP_VERSION } from './constants';

const initialState = {
  blacklist: {
    appready: true,
    charts: {},
    loading: false,
    markets: [],
    status: true,
    trades: [],
    trendings: [],
    updateat: Date.now(),
    version: APP_VERSION,
  },
  key: `${APP_NAME}::store`,
  storage,
  whitelist: {
    archives: [],
    exchanges: {},
    portfolio: {},
    settings: {
      autopoll: false,
      chart_range: '1',
      currency: 'eur',
      darkmode: false,
      indice: true,
      pollingms: 5000,
    },
    transactions: [],
  },
};

export default initialState;
