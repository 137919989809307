import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../../core/hooks';
import { formatPrice, formatToday, formatVolume } from '../../../../core/utils';

const useStyles = createUseStyles({
  transaction: ({ theme }) => ({
    '&.odd': {
      background: theme.highlight,
      color: theme.primary,
    },
  }),
});

const TransactionItemComponent = ({ odd, transaction }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div
      key={transaction.uid}
      className={classnames(
        classes.transaction,
        'flex-columns flex-between p12 fs10',
        { odd }
      )}>
      <span className="text-left" style={{ width: '25%' }}>
        {formatToday(transaction.mtime)}
      </span>
      <span className="text-center" style={{ width: '25%' }}>
        {formatPrice(transaction.placed, transaction.currency)}
      </span>
      <span className="text-center" style={{ width: '25%' }}>
        {formatPrice(transaction.buying, transaction.currency)}
      </span>
      <span className="text-right" style={{ width: '25%' }}>
        {formatVolume(transaction.volume)}
      </span>
    </div>
  );
};

TransactionItemComponent.propTypes = {
  odd: PropTypes.bool.isRequired,
  transaction: PropTypes.shape().isRequired,
};

TransactionItemComponent.displayName = 'TransactionItemComponent';

export default TransactionItemComponent;
