import React from 'react';
import { IoFileTrayFullSharp as ArchivesIcon } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectArchives } from '../../../../core/selectors';

const ArchivesButtonComponent = React.memo(() => {
  const archives = useSelector(selectArchives);
  const hasArchives = archives.length > 0;
  return (
    <Link className="text-center" disabled={!hasArchives} to="/archives">
      <ArchivesIcon size={24} style={{ opacity: 0.45 }} />
    </Link>
  );
});

ArchivesButtonComponent.displayName = 'ArchivesButtonComponent';

export default ArchivesButtonComponent;
