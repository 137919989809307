import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import configure from './configure';
import { InitialStateType } from './types';

const StoreProvider = React.memo(
  ({ children, debug, initialState, middlewares, reducers }) => {
    const { persistor, store } = configure(
      initialState,
      reducers,
      middlewares,
      debug
    );
    return (
      <Provider store={store}>
        {persistor && (
          <PersistGate loading={null} persistor={persistor}>
            {children}
          </PersistGate>
        )}
        {!persistor && children}
      </Provider>
    );
  }
);

StoreProvider.defaultProps = {
  middlewares: [],
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  debug: PropTypes.bool.isRequired,
  initialState: InitialStateType.isRequired,
  middlewares: PropTypes.arrayOf(PropTypes.func),
  reducers: PropTypes.shape().isRequired,
};

StoreProvider.displayName = 'StoreProvider';

export default StoreProvider;
