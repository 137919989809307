import { IoTrophy as TrophyIcon } from 'react-icons/io5';

import features from '../../../configs/features';
import PageComponent from './page';

const route = {
  Component: PageComponent,
  exact: true,
  icon: features.trades ? TrophyIcon : null,
  iconSize: 24,
  id: PageComponent.displayName,
  order: 1,
  path: '/trendings',
};

export default route;
