export const formatPercent = (percent, decorate = true) => {
  if (!percent) return '-';
  const fixed = Math.abs(percent).toFixed(2);
  if (!decorate) return fixed;
  const plusminus = percent >= 0 ? '+' : '-';
  const formatted = `${plusminus}${fixed} %`;
  return formatted;
};

export default formatPercent;
