import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import ThemesContext from './themes.context';

const ThemesProvider = ({ children, themes }) => {
  const { darkmode } = useSelector(_ => _.settings);
  const key = darkmode ? 'night' : 'day';
  return (
    <ThemesContext.Provider value={{ theme: themes[key] }}>
      {children}
    </ThemesContext.Provider>
  );
};

ThemesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  themes: PropTypes.shape().isRequired,
};

ThemesProvider.displayName = 'ThemesProvider';

export default ThemesProvider;
