import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IoBagAddOutline as TransactionAdd,
  IoCloseCircle as CloseIcon,
  IoWallet as TransactionUpdate,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  column: {
    composes: ['text-center', 'px6', 'py12', 'flex-0'],
    minWidth: 36,
    width: 36,
  },
  icon: ({ theme }) => ({
    '&.update': { color: theme.primary },
    color: theme.secondary,
  }),
});

const TransactionButtonComponent = ({ onClick, opened, value }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const Icon = value ? TransactionUpdate : TransactionAdd;
  return (
    <div className={classnames(classes.column, { value })}>
      <button type="button" onClick={onClick}>
        {opened && <CloseIcon className={classes.icon} size={18} />}
        {!opened && (
          <Icon
            className={classnames(classes.icon, { update: value })}
            size={18}
          />
        )}
      </button>
    </div>
  );
};

TransactionButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
};

TransactionButtonComponent.displayName = 'TransactionButtonComponent';

export default TransactionButtonComponent;
