import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';
import { CoinMarketPrice, CoinMetas, CoinRank } from '../../components/coin';

const useStyles = createUseStyles({
  coin: ({ theme }) => ({
    '&.first': { marginTop: '0 !important' },
    '&.opened': {
      borderRadius: 8,
      marginBottom: 6,
      marginTop: 6,
      overflow: 'hidden',
    },
    background: theme.white,
  }),
});

const TrendingsCard = React.memo(({ coin, currency }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div
      className={classnames(classes.coin, 'flex-1 flex-rows flex-start py6')}>
      <div className="flex-columns flex-around items-center">
        <CoinRank value={coin.market_cap_rank} />
        <CoinMetas
          id={coin.id}
          image={coin.image}
          name={coin.name}
          symbol={coin.symbol}
        />
        <CoinMarketPrice
          ath={coin.ath}
          athDate={coin.ath_date}
          currency={currency}
          value={coin.current_price}
        />
      </div>
    </div>
  );
});

TrendingsCard.propTypes = {
  coin: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
};

TrendingsCard.displayName = 'TrendingsCard';

export default TrendingsCard;
