import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import Card from './card';

const useStyles = createUseStyles({
  board: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

const MarketsBoardComponent = () => {
  const classes = useStyles();
  const markets = useSelector(_ => _.markets);
  const portfolio = useSelector(_ => _.portfolio);
  const [opened, setOpened] = useState(null);

  const onOpenTransaction = useCallback(id => {
    setOpened(id);
  }, []);

  return (
    <div className={classnames(classes.board, 'is-relative shadow')}>
      {markets.map((coin, index) => {
        const isTopTen = coin.market_cap_rank <= 10;
        const owned = !!Object.keys(portfolio).find(key => coin.id === key);
        return (
          <Card
            key={coin.id}
            coin={coin}
            first={index === 0}
            opened={opened === coin.id}
            owned={owned}
            top={isTopTen}
            onOpen={onOpenTransaction}
          />
        );
      })}
    </div>
  );
};

MarketsBoardComponent.displayName = 'MarketsBoardComponent';

export default MarketsBoardComponent;
