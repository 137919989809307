import { createCachedSelector } from 're-reselect';

import { getCumulated } from '../../../../core/utils';

const getCoinId = (_, id) => id;
const getTransactions = _ => _.transactions;
const getPortfolio = _ => _.portfolio;

const getCurrency = _ => _.settings.currency;

const selectCoinTransactionsOverall = createCachedSelector(
  getTransactions,
  getPortfolio,
  getCurrency,
  getCoinId,
  (transactions, portfolio, currency, id) => {
    const price = portfolio[id].current_price;
    const items = transactions.filter(item => item.coin === id);
    const volumes = getCumulated('volume', items);
    const result = { currency, price, volumes };
    return result;
  }
)((_, id) => `coin::transactions::overall::${id}`);

export default selectCoinTransactionsOverall;
