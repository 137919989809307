import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IoEnter as DepotIcon,
  IoExit as WithdrawalIcon,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({
    '&.disabled': { color: theme.secondary },
    '&.withdrawal': { color: theme.red },
    color: theme.green,
  }),
});

const TransactionFormSubmitComponent = ({ disabled, withdrawal }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <button
      className="fs8 is-uppercase is-bold mr6"
      disabled={disabled}
      type="submit">
      {!withdrawal && (
        <DepotIcon
          className={classnames(classes.icon, {
            disabled,
            withdrawal,
          })}
          size={32}
        />
      )}
      {withdrawal && (
        <WithdrawalIcon
          className={classnames(classes.icon, {
            disabled,
            withdrawal,
          })}
          size={32}
        />
      )}
    </button>
  );
};

TransactionFormSubmitComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  withdrawal: PropTypes.bool.isRequired,
};

TransactionFormSubmitComponent.displayName = 'TransactionFormSubmitComponent';

export default TransactionFormSubmitComponent;
