import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import { formatPrice, formatVolume } from '../../../../core/utils';
import selectVolumes from './selectors';

const useStyles = createUseStyles({
  button: ({ theme }) => ({
    background: theme.goldy,
    borderRadius: 4,
    color: theme.white,
    width: 70,
  }),
  input: ({ theme }) => ({
    background: theme.white,
    borderRadius: 4,
    color: theme.primary,
    width: 70,
  }),
  summary: ({ theme }) => ({
    background: theme.highlight,
    borderRadius: '8px 8px 0 0',
    color: theme.primary,
  }),
  text: ({ theme }) => ({ color: theme.primary, width: 70 }),
});

const TransactionsOverallComponent = ({ coinId }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { currency, price, volumes } = useSelector(_ =>
    selectVolumes(_, coinId)
  );
  const [sellPrice, setSellPrice] = useState(price * volumes);

  const submitHandler = useCallback(
    ({ rate }) => {
      const placed = Number(rate);
      const sell = placed * volumes;
      setSellPrice(sell);
    },
    [volumes]
  );

  const { form, handleSubmit } = useForm({
    initialValues: { rate: price },
    onSubmit: submitHandler,
  });
  const field = useField('rate', form);

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={classnames(
          classes.summary,
          'flex-columns flex-center items-center px24 py12 fs12 is-bold'
        )}>
        <div>
          <span>{formatVolume(volumes, 6)}</span>
        </div>
        <span className="is-bold mx6">x</span>
        <div className={classnames(classes.input, 'flex-0 mx6')}>
          <input
            {...field.input}
            className={classnames(classes.text, 'text-center fs12 py3')}
            type="text"
          />
        </div>
        <span className="is-bold mx6">=</span>
        <button
          className={classnames(classes.button, 'fs12 is-bold text-center py3')}
          type="submit">
          {formatPrice(sellPrice, currency)}
        </button>
      </div>
    </form>
  );
};

TransactionsOverallComponent.propTypes = {
  coinId: PropTypes.string.isRequired,
};

TransactionsOverallComponent.displayName = 'TransactionsOverallComponent';

export default TransactionsOverallComponent;
