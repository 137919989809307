import PropTypes from 'prop-types';
import React from 'react';
import { IoOptions as OptionsIcon } from 'react-icons/io5';

const SettingsButtonComponent = React.memo(({ onClick }) => (
  <button type="button" onClick={onClick}>
    <OptionsIcon opacity={0.45} size={24} />
  </button>
));

SettingsButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

SettingsButtonComponent.displayName = 'SettingsButtonComponent';

export default SettingsButtonComponent;
