import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IoImageSharp as ImageIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { Link, useHistory } from 'react-router-dom';

// import { BINANCE_BASE_URL } from '../../../configs/constants';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  link: ({ theme }) => ({
    color: theme.primary,
  }),
  name: {},
  pill: ({ theme }) => ({
    color: theme.primary,
  }),
  rank: {},
});

function getLinkTo(id, { pathname, state }) {
  // TODO trouver un moyen de rendre dynamique
  // le chemin `/charts/${id}`
  // en recuperant le displayName de la route charts ?
  const isChartPage = pathname.indexOf('/charts') === 0;
  const result = isChartPage
    ? { pathname: state.from, state: {} }
    : { pathname: `/charts/${id}`, state: { from: pathname } };
  return result;
}

const CoinMetasComponent = React.memo(
  ({ id, image, large, name, rank, symbol }) => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles({ theme });

    // const externalLink = `${BINANCE_BASE_URL}/${symbol.toUpperCase()}_USDT`;
    const linkTo = getLinkTo(id, history.location);

    return (
      <div className="flex-columns flex-between items-center flex-1">
        <div className="flex-0 mr12">
          {(image && (
            <Link to={linkTo}>
              <img
                alt={name}
                height="auto"
                src={image}
                width={large ? 32 : 16}
              />
            </Link>
          )) || <ImageIcon size={16} />}
        </div>
        <div className="flex-1 text-left">
          <div
            className={classnames(
              classes.pill,
              'is-bold is-block is-uppercase',
              {
                fs10: large,
                fs8: !large,
                mb3: !large,
                mb6: large,
                pill: large,
              }
            )}
            style={{
              background: !large ? 'transparent' : theme.highlight,
            }}>
            <Link
              className={classnames(classes.link, 'no-underline')}
              to={linkTo}>
              {symbol}
            </Link>
          </div>
          <div className="is-bold">
            <Link
              className={classnames(classes.link, 'no-underline', {
                fs14: !large,
                fs16: large,
              })}
              to={linkTo}>
              <span className={classes.name}>{name}</span>
              {rank && <small className="is-light ml6 fs10">#{rank}</small>}
            </Link>
          </div>
        </div>
      </div>
    );
  }
);

CoinMetasComponent.defaultProps = {
  large: false,
  rank: null,
};

CoinMetasComponent.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rank: PropTypes.number,
  symbol: PropTypes.string.isRequired,
};

CoinMetasComponent.displayName = 'CoinMetasComponent';

export default CoinMetasComponent;
