import defaaultStorage from 'redux-persist/lib/storage';

const getStorageConfig = initialState => ({
  blacklist: Object.keys(initialState.blacklist),
  key: initialState.key,
  storage: initialState.storage || defaaultStorage,
  whitelist: Object.keys(initialState.whitelist),
});

export default getStorageConfig;
