import PropTypes from 'prop-types';
import React from 'react';

import FeaturesContext from './features.context';

const FeaturesProvider = React.memo(({ children, features }) => (
  <FeaturesContext.Provider value={{ features }}>
    {children}
  </FeaturesContext.Provider>
));

FeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  features: PropTypes.shape().isRequired,
};

FeaturesProvider.displayName = 'FeaturesProvider';

export default FeaturesProvider;
