import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IoCaretDown as DownIcon, IoCaretUp as UpIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../core/hooks';
import { formatPrice } from '../../../core/utils';

const useStyles = createUseStyles({
  percent: ({ theme }) => ({
    background: theme.goldy,
    color: theme.white,
  }),
  price: ({ theme }) => ({
    color: theme.primary,
  }),
  prices: {
    maxWidth: 120,
  },
});

const CoinProfitsClosedComponent = React.memo(
  ({ bankable, healthy, wallet }) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { currency } = useSelector(_ => _.settings);

    return (
      <div
        className={classnames(
          classes.prices,
          'flex-rows flex-end items-end flex-0'
        )}>
        <div className="flex-columns flex-end items-center flex-0">
          <div
            className={classnames(
              classes.percent,
              'is-bold text-right fs12 pill flex-columns flex-end items-center'
            )}>
            <span className="mr6">
              {healthy && <UpIcon size={12} />}
              {!healthy && <DownIcon size={12} />}
            </span>
            <span>{formatPrice(bankable * -1, currency)}</span>
          </div>
        </div>
        <div
          className={classnames(classes.price, 'text-right fs18 mt6', {
            'is-bold': true,
          })}>
          {formatPrice(wallet, currency)}
        </div>
      </div>
    );
  }
);

CoinProfitsClosedComponent.propTypes = {
  bankable: PropTypes.number.isRequired,
  healthy: PropTypes.bool.isRequired,
  wallet: PropTypes.number.isRequired,
};

CoinProfitsClosedComponent.displayName = 'CoinProfitsClosedComponent';

export default CoinProfitsClosedComponent;
