import axios from 'axios';

import { EVENT_TYPES, GECKO_API_BASE_URL } from '../../configs/constants';

const transformDataToPayload = ({ rates }) => {
  const OneBitcoinVsEuro = rates.eur.value;
  const OneBitcoinVsDollar = rates.usd.value;
  const payload = {
    'eur-to-usd': OneBitcoinVsDollar / OneBitcoinVsEuro,
    'usd-to-eur': OneBitcoinVsEuro / OneBitcoinVsDollar,
  };
  return payload;
};

export const loadExchanges = () => dispatch => {
  dispatch({ type: EVENT_TYPES.LOADING_START });
  const url = `${GECKO_API_BASE_URL}/exchange_rates`;
  return axios
    .get(url)
    .then(({ data }) => {
      const payload = transformDataToPayload(data);
      dispatch({ payload, type: EVENT_TYPES.EXCHANGES_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadExchanges;
