import { number, shape, string } from 'prop-types';

export const TradeSchema = {
  asset: string.isRequired,
  capital: number.isRequired,
  currency: string.isRequired,
  image: string,
  leverage: number.isRequired,
  loss: number.isRequired,
  percent: number.isRequired,
  result: number.isRequired,
  status: string.isRequired,
  symbol: string.isRequired,
  type: string.isRequired,
  wins: number.isRequired,
};

export const TradeType = shape(TradeSchema);
