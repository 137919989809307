import './styles/index.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'react-vis/dist/style.css';
import 'tippy.js/themes/material.css';

import React from 'react';
import { HashRouter } from 'react-router-dom';
import thunk from 'redux-thunk';

import {
  APP_NAME,
  APP_VERSION,
  IS_DEVELOPMENT,
  PUBLIC_URL,
} from '../configs/constants';
import features from '../configs/features';
import states from '../configs/states';
import themes from '../configs/themes';
import {
  FeaturesProvider,
  StoreProvider,
  ThemesProvider,
} from '../core/contexts';
import reducers from '../core/reducers';
import Layout from './components/layout';
import routes from './pages/routes';

const Application = () => {
  // eslint-disable-next-line
  console.log(`${APP_NAME} v${APP_VERSION}`);
  return (
    <React.StrictMode>
      <StoreProvider
        debug={IS_DEVELOPMENT}
        initialState={states}
        middlewares={[thunk]}
        reducers={reducers}>
        <HashRouter basename={PUBLIC_URL}>
          <FeaturesProvider features={features}>
            <ThemesProvider themes={themes}>
              <Layout routes={routes} />
            </ThemesProvider>
          </FeaturesProvider>
        </HashRouter>
      </StoreProvider>
    </React.StrictMode>
  );
};

Application.displayName = 'Application';

export default Application;
