import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../core/hooks';
import { ToggleCurrency } from '../../components/buttons';
import Chart from './chart';
import { Zoom } from './controls';
import ProfitCalculator from './profit-calculator';
import { selectChart, selectCoin } from './selectors';
import Summary from './summary';

const useStyles = createUseStyles({
  card: ({ theme }) => ({ background: theme.white }),
});

const BoardComponent = ({ coinid }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { currency } = useSelector(_ => _.settings);
  const coin = useSelector(_ => selectCoin(_, coinid));
  const charts = useSelector(_ => selectChart(_, coinid));

  const [scale, setScale] = useState(1);
  const onScaleChange = useCallback(value => setScale(value), []);

  return (
    <div>
      <div className="controls">
        <ToggleCurrency />
        <Zoom onChange={onScaleChange} />
      </div>
      <div>
        <ProfitCalculator
          ath={coin.ath}
          athDate={coin.ath_date}
          currency={coin.currency}
          price={coin.current_price}
        />
      </div>
      <div className={classnames(classes.card, 'card pt24 is-relative')}>
        <Summary coin={coin} currency={currency} />
        <Chart currency={currency} data={charts} scale={scale} />
      </div>
    </div>
  );
};

BoardComponent.propTypes = {
  coinid: PropTypes.string.isRequired,
};

BoardComponent.displayName = 'BoardComponent';

export default BoardComponent;
