import 'react-vis/dist/style.css';

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({
  button: {
    opacity: 0.35,
  },
});

const ZoomControlComponent = ({ maxScale, onChange }) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const loading = useSelector(_ => _.loading);

  const zoomHandler = useCallback(() => {
    let next = scale + 1;
    next = next <= maxScale ? next : 1;
    setScale(next);
    onChange(next);
  }, [scale, setScale, maxScale, onChange]);

  return (
    <div className="flex-1 text-center">
      <button
        className={classes.button}
        disabled={loading}
        type="button"
        onClick={zoomHandler}>
        <span>ZOOM +/-</span>
      </button>
    </div>
  );
};

ZoomControlComponent.defaultProps = {
  maxScale: 4,
};

ZoomControlComponent.propTypes = {
  maxScale: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

ZoomControlComponent.displayName = 'ZoomControlComponent';

export default ZoomControlComponent;
