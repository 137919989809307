import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';
import Balance from './balance';
import Division from './division';
import Settings from './settings';

const useStyles = createUseStyles({
  balance: {
    height: '100%',
    transition: 'top 0.3s, left 0.3s',
    width: '100%',
    zIndex: 20,
  },
  button: ({ theme }) => ({
    '&.active': { opacity: 1 },
    background: theme.white,
    borderRadius: 10,
    height: 20,
    opacity: 0.45,
    width: 20,
  }),
  card: {
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
  wallet: {
    borderRadius: 8,
    height: 180,
  },
});

const WalletCompopnent = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showView, setShowView] = useState('settings');
  const [showBalance, setShowBalance] = useState(true);

  const toggleView = useCallback(
    view => {
      if (view) {
        setShowView(view);
        setShowBalance(false);
      } else setShowBalance(true);
    },
    [setShowView]
  );

  const top = !showBalance ? 180 : 0;
  const showDivision = showView === 'division';
  return (
    <div
      className={classnames(classes.wallet, 'is-relative no-overflow shadow')}>
      <div className={classnames(classes.card, 'is-absolute is-trbl')}>
        {!showDivision && <Settings onToggle={toggleView} />}
        {showDivision && <Division onToggle={toggleView} />}
      </div>
      <div
        className={classnames(classes.balance, 'is-absolute is-trbl')}
        style={{ top }}>
        <Balance onToggle={toggleView} />
      </div>
    </div>
  );
});

WalletCompopnent.displayName = 'WalletCompopnent';

export default WalletCompopnent;
