import PageComponent from './page';

const route = {
  // NOTE notfound path/page
  // should always be last
  Component: PageComponent,
  exact: true,
  id: PageComponent.displayName,
  path: '*',
};

export default route;
