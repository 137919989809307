import axios from 'axios';
import pick from 'lodash.pick';

import { EVENT_TYPES, GECKO_API_BASE_URL } from '../../configs/constants';

const transformDataToPayload = (data, currency) => {
  const pickedKeys = [
    'id',
    'ath',
    'current_price',
    'market_cap_rank',
    'price_change_percentage_1h_in_currency',
  ];
  const results = data.reduce((acc, coin) => {
    const values = pick(coin, pickedKeys);
    const update = { ...values, currency };
    const next = [...acc, update];
    return next;
  }, []);
  return results;
};

export const loadPrices = curr => (dispatch, getState) => {
  dispatch({ type: EVENT_TYPES.LOADING_START });

  const { portfolio, settings } = getState();
  const ids = Object.keys(portfolio).join(',');
  const url = `${GECKO_API_BASE_URL}/coins/markets`;
  return axios
    .get(url, {
      params: {
        ids,
        order: 'market_cap_desc',
        page: 1,
        per_page: 100,
        price_change_percentage: '1h',
        sparkline: false,
        vs_currency: curr || settings.currency,
      },
    })
    .then(({ data }) => {
      const payload = transformDataToPayload(data, curr || settings.currency);
      dispatch({ payload, type: EVENT_TYPES.PRICES_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadPrices;
