import PropTypes from 'prop-types';
import React from 'react';

import AxisX from './axis-x';
import AxisY from './axis-y';

const WIDTH = 1200;
const HEIGHT = 200;

const ChartComponent = ({ currency, data, scale }) => (
  <div className="is-relative no-overflow">
    <AxisY
      currency={currency}
      data={data}
      scale={scale}
      size={{ height: HEIGHT, width: WIDTH }}
    />
    <AxisX data={data} scale={scale} size={{ height: HEIGHT, width: WIDTH }} />
  </div>
);

ChartComponent.defaultProps = {
  currency: '€',
};

ChartComponent.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    buying: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
    rates: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
  }).isRequired,
  scale: PropTypes.number.isRequired,
};

ChartComponent.displayName = 'ChartComponent';

export default ChartComponent;
