import PropTypes from 'prop-types';
import React from 'react';
import { IoClose as CloseIcon } from 'react-icons/io5';

const CloseButtonComponent = React.memo(({ onClick }) => (
  <button type="button" onClick={onClick}>
    <CloseIcon size={24} />
  </button>
));

CloseButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

CloseButtonComponent.displayName = 'CloseButtonComponent';

export default CloseButtonComponent;
