import React from 'react';

import { PollerProvider } from '../../../core/contexts';
import { loadMarkets } from '../../../core/services';
import Board from './board';
import Controls from './controls';

const MarketsPageComponent = React.memo(() => (
  <PollerProvider once action={loadMarkets}>
    <Controls />
    <Board />
  </PollerProvider>
));

MarketsPageComponent.displayName = 'MarketsPageComponent';

export default MarketsPageComponent;
