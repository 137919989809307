import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { IoCaretDown as DownIcon, IoCaretUp as UpIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import { formatPercent, formatPrice } from '../../../../core/utils';

const useStyles = createUseStyles({
  pill: ({ theme }) => ({
    background: 'rgba(0, 0, 0, 0.25)',
    color: theme.white,
  }),
  toolbar: ({ theme }) => ({
    '& svg': { color: theme.white },
  }),
  wrapper: ({ theme }) => ({
    '&.lossy': { background: theme.red },
    borderRadius: 8,
    color: theme.white,
    height: '100%',
    width: '100%',
  }),
});

const BalanceComponent = React.memo(({ loss, percent, profit, win }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { currency } = useSelector(_ => _.settings);
  const health = useRef({ diff: 0, lossy: false, profit: 0 });

  const [showWinLoss, setShowWinLoss] = useState(false);

  useEffect(() => {
    const diff = health.current.profit - profit;
    const lossy = diff < 0;
    health.current = { diff, lossy, profit };
  }, [profit, health]);

  return (
    <button
      className={classnames(
        classes.pill,
        'is-block mx6 mt3 pill text-right no-overflow'
      )}
      type="button"
      onClick={() => setShowWinLoss(!showWinLoss)}>
      <div className="fs12 is-bold">
        {showWinLoss && <span>{formatPrice(loss, currency)}</span>}
        {!showWinLoss && <span>{formatPrice(profit, currency)}</span>}
      </div>
      <div className="fs8">
        {showWinLoss && <span>{formatPrice(win, currency)}</span>}
        {!showWinLoss && <span>{formatPercent(percent)}</span>}
        <span className="ml6">
          {health.current.lossy && <UpIcon size={8} />}
          {!health.current.lossy && <DownIcon size={8} />}
        </span>
      </div>
    </button>
  );
});

BalanceComponent.propTypes = {
  loss: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  profit: PropTypes.number.isRequired,
  win: PropTypes.number.isRequired,
};

BalanceComponent.displayName = 'BalanceComponent';

export default BalanceComponent;
