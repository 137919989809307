import get from 'lodash.get';
import last from 'lodash.last';
import { createSelector } from 'reselect';

import { getCumulated, getPercent } from '../utils';

const DEFAULT_VALUE = [];

const getPrices = _ => _.prices;
const getArchives = _ => _.archives;
const getPortfolio = _ => _.portfolio;
const getTransactions = _ => _.transactions;
const getCurrency = _ => _.settings.currency;

export const selectArchives = createSelector(
  getPortfolio,
  getArchives,
  getTransactions,
  getPrices,
  getCurrency,
  (portfolio, archives, transactions, prices, currency) => {
    const coins =
      prices &&
      Object.keys(portfolio)
        .filter(key => archives.includes(key))
        .map(coinid => {
          const coin = portfolio[coinid];
          const items = transactions.filter(obj => obj.coin === coinid);
          /* getters */
          const price = get(last(prices[coinid]), currency);
          const placed = getCumulated('placed', items);
          const volume = getCumulated('volume', items);
          const buying = getCumulated('buying', items, v => v / items.length);
          const wallet = volume * price;
          const profit = wallet - placed;
          const bankable = placed - wallet;
          const lossy = wallet < placed;
          const percent = getPercent(placed, wallet);
          /* result */
          return {
            ...coin,
            bankable,
            buying,
            lossy,
            percent,
            placed,
            price,
            profit,
            volume,
            wallet,
          };
        });
    return coins || DEFAULT_VALUE;
  }
);

export default selectArchives;
