import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import { formatPrice } from '../../../../core/utils';
import { DivisionButton, ReloadButton, SettingsButton } from '../../buttons';
import Archives from './archives';
import Health from './health';
import Polling from './polling';
import { selectBalance } from './selectors';

const useStyles = createUseStyles({
  pill: ({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.25)',
    color: theme.white,
  }),
  toolbar: ({ theme }) => ({
    '& svg': { color: theme.white },
  }),
  wrapper: ({ theme }) => ({
    '&.lossy': { background: theme.red },
    borderRadius: 8,
    color: theme.white,
    height: '100%',
    width: '100%',
  }),
});

const BalanceComponent = React.memo(({ onToggle }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const balance = useSelector(selectBalance);
  const { autopoll, currency } = useSelector(_ => _.settings);

  return (
    <div
      className={classnames(
        classes.wrapper,
        'flex-columns is-relative gradient-green',
        { lossy: balance.lossy }
      )}>
      <div className="text-center flex-1 px24 py48">
        <span className="is-medium is-block mb12 fs44">
          {formatPrice(balance.wallet, currency)}
        </span>
        <div className="flex-columns items-center flex-center">
          <span className="is-block is-light mx6 fs24">
            {formatPrice(balance.placed, currency)}
          </span>
          <Health
            loss={balance.loss}
            percent={balance.percent}
            profit={balance.profit}
            win={balance.win}
          />
        </div>
      </div>
      <div
        className={classnames(
          classes.toolbar,
          'flex-rows flex-between items-center flex-0 px6 py12 toolbar'
        )}>
        <SettingsButton onClick={() => onToggle('settings')} />
        <DivisionButton onClick={() => onToggle('division')} />
        <Archives />
        {autopoll && <Polling />}
        {!autopoll && <ReloadButton />}
      </div>
    </div>
  );
});

BalanceComponent.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

BalanceComponent.displayName = 'BalanceComponent';

export default BalanceComponent;
