import {
  arrayOf,
  bool,
  element,
  elementType,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const RouteSchema = {
  Component: oneOfType([node, element, elementType]).isRequired,
  exact: bool.isRequired,
  icon: oneOfType([node, element, elementType]),
  iconSize: number,
  id: string.isRequired,
  order: number,
  path: oneOfType([string, arrayOf(string)]).isRequired,
};

export const RouteType = shape(RouteSchema);
