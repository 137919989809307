import pick from 'lodash.pick';

export const hydrateEntities = (state, payload, schema) => {
  const ids = Object.keys(state);
  const keys = Object.keys(schema);
  const results = payload
    .filter(coin => ids.includes(coin.id))
    .reduce((acc, coin) => {
      const update = pick(coin, keys);
      const previous = state[coin.id];
      const merged = { ...previous, ...update };
      return { ...acc, [coin.id]: merged };
    }, {});
  return { ...state, ...results };
};

export default hydrateEntities;
