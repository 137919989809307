import { v4 as uuidv4 } from 'uuid';

import { EVENT_TYPES } from '../../configs/constants';

export const createTransaction = (coin, values, currency) => ({
  payload: {
    buying: values.buying,
    coin: coin.id,
    currency,
    mtime: Date.now(),
    placed: values.placed,
    uid: uuidv4(),
    volume: values.volume,
  },
  type: EVENT_TYPES.TRANSACTION_CREATE,
});

export default createTransaction;
