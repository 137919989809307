import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IoCaretDown as ArrowDown,
  IoCaretUp as ArrowUp,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  column: {
    composes: ['text-center', 'px6', 'py12', 'flex-0'],
    minWidth: 32,
    width: 32,
  },
  icon: ({ theme }) => ({
    '&.lossy': { color: theme.red },
    color: theme.green,
  }),
});

const CoinHealthColumnComponent = ({ value }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const lossy = value < 0;
  const HealthIcon = lossy ? ArrowDown : ArrowUp;
  return (
    <div className={classes.column}>
      {value && <HealthIcon className={classnames(classes.icon, { lossy })} />}
      {!value && <span />}
    </div>
  );
};

CoinHealthColumnComponent.defaultProps = {
  value: null,
};

CoinHealthColumnComponent.propTypes = {
  value: PropTypes.number,
};

CoinHealthColumnComponent.displayName = 'CoinHealthColumnComponent';

export default CoinHealthColumnComponent;
