import { EVENT_TYPES } from '../../configs/constants';

export const appready = (state = false, action) => {
  switch (action.type) {
    case EVENT_TYPES.DATABASE_LOADED:
      return true;
    default:
      return state;
  }
};

export const loading = (state = true, action) => {
  switch (action.type) {
    case EVENT_TYPES.LOADING_START:
      return true;
    case EVENT_TYPES.LOADING_COMPLETED:
      return false;
    default:
      return state;
  }
};

export const settings = (state = '', { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.STORE_RESET:
      return payload.settings;
    case EVENT_TYPES.SETTINGS_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const version = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};
