import { func, shape, string } from 'prop-types';

export const StorageType = shape({
  getItem: func.isRequired,
  removeItem: func.isRequired,
  setItem: func.isRequired,
});

export const InitialStateType = shape({
  blacklist: shape().isRequired,
  key: string.isRequired,
  storage: StorageType,
  whitelist: shape().isRequired,
});
