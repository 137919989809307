import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IoChevronBackOutline as LeftArrow,
  IoChevronForward as RightArrow,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  pagination: ({ theme }) => ({
    background: theme.white,
    borderRadius: 16,
    color: theme.primary,
  }),
});

const PaginationControlComponent = ({ hasMoreToLoad, onChange, page }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const loading = useSelector(_ => _.loading);

  return (
    <div
      className={classnames(
        classes.pagination,
        'px12 py6 flex-columns flex-center items-center'
      )}>
      <button
        className="mr6"
        disabled={loading || page === 1}
        type="button"
        onClick={() => onChange(-1)}>
        <LeftArrow />
      </button>
      <button
        className="ml6"
        disabled={loading || !hasMoreToLoad}
        type="button"
        onClick={() => onChange(1)}>
        <RightArrow />
      </button>
    </div>
  );
};

PaginationControlComponent.propTypes = {
  hasMoreToLoad: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

PaginationControlComponent.displayName = 'PaginationControlComponent';

export default PaginationControlComponent;
