import React from 'react';

import { PollerProvider } from '../../../core/contexts';
import { loadTrendings } from '../../../core/services';
import Wallet from '../../components/wallet';
import Board from './board';

const TrendingsPageComponent = React.memo(() => (
  <PollerProvider action={loadTrendings}>
    <Wallet />
    <Board />
  </PollerProvider>
));

TrendingsPageComponent.displayName = 'TrendingsPageComponent';

export default TrendingsPageComponent;
