import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  BsToggleOff as ToggleOffIcon,
  BsToggleOn as ToggleOnIcon,
} from 'react-icons/bs';
import { IoFlash as OnIcon, IoFlashOutline as OffIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '../../../core/actions';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({ color: theme.highlight }),
  input: ({ theme }) => ({
    background: theme.white,
    borderRadius: 4,
    lineHeight: 2,
    width: 44,
  }),
  label: ({ theme }) => ({
    '&.disabled': { opacity: 0.35 },
    color: theme.highlight,
  }),
  right: { width: 80 },
});

const AutoPollToggleComponent = React.memo(({ showLabel }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const settings = useSelector(_ => _.settings);

  const settingsHandler = useCallback(() => {
    const autopoll = !settings.autopoll;
    dispatch(updateSettings({ ...settings, autopoll }));
  }, [settings, dispatch]);

  const delayHandler = useCallback(
    value => {
      const pollingms = value * 1000;
      dispatch(updateSettings({ ...settings, pollingms }));
    },
    [settings, dispatch]
  );

  const isAuto = settings.autopoll;
  const secs = settings.pollingms / 1000;
  const Icon = isAuto ? ToggleOnIcon : ToggleOffIcon;

  return (
    <div className="flex-columns flex-between items-center">
      {showLabel && (
        <div className="flex-columns flex-start items-center">
          <span className="mr12">Polling</span>
          <div>
            <button
              disabled={secs === 5 || settings.autopoll}
              type="button"
              onClick={() => delayHandler(secs - 1)}>
              -
            </button>
            <span className="is-lowercase mx6">{`${secs}s`}</span>
            <button
              disabled={secs === 60 || settings.autopoll}
              type="button"
              onClick={() => delayHandler(secs + 1)}>
              +
            </button>
          </div>
        </div>
      )}
      <div className={classes.right}>
        <button
          className="is-bold fs10 is-uppercase flex-columns flex-around items-center"
          type="button"
          onClick={settingsHandler}>
          <span className={classnames(classes.label, { disabled: isAuto })}>
            <OffIcon className="fs16" />
          </span>
          <Icon className={classnames(classes.icon, 'mx12')} size={24} />
          <span className={classnames(classes.label, { disabled: !isAuto })}>
            <OnIcon className="fs16" />
          </span>
        </button>
      </div>
    </div>
  );
});

AutoPollToggleComponent.propTypes = {
  showLabel: PropTypes.bool.isRequired,
};

AutoPollToggleComponent.displayName = 'AutoPollToggleComponent';

export default AutoPollToggleComponent;
