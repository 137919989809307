import { createSelector } from 'reselect';

import { convertPrice, getCumulated, getPercent } from '../../../../core/utils';

const getArchives = _ => _.archives;
const getPortfolio = _ => _.portfolio;
const getExchanges = _ => _.exchanges;
const getTransactions = _ => _.transactions;
const getCurrency = _ => _.settings.currency;

const getTransactionsPlaced = (transactions, current, exchanges) => {
  const result = transactions.reduce((acc, item) => {
    const { currency, placed } = item;
    const shouldConvert = currency !== current;
    const value = shouldConvert
      ? convertPrice(placed, currency, exchanges)
      : placed;
    const next = acc + value;
    return next;
  }, 0);
  return result;
};

export const selectBalance = createSelector(
  getPortfolio,
  getArchives,
  getTransactions,
  getCurrency,
  getExchanges,
  (portfolio, archives, transactions, currency, exchanges) => {
    const balances = Object.keys(portfolio)
      // filter archives
      .filter(coinid => !archives.includes(coinid))
      .map(coinid => portfolio[coinid])
      .map(coin => {
        // filter transactions for a coin
        const items = transactions.filter(item => item.coin === coin.id);
        // get transactions total volume
        const volume = getCumulated('volume', items);
        // get transactions total placed
        const placed = getTransactionsPlaced(items, currency, exchanges);
        // caculate wallet
        const wallet = volume * coin.current_price;
        const winloss = wallet - placed;
        const next = { placed, wallet, winloss };
        return next;
      })
      .reduce(
        (acc, values) => ({
          loss: values.winloss < 0 ? acc.loss + values.winloss : acc.loss,
          placed: acc.placed + values.placed,
          wallet: acc.wallet + values.wallet,
          win: values.winloss >= 0 ? acc.win + values.winloss : acc.win,
        }),
        { loss: 0, placed: 0, wallet: 0, win: 0 }
      );

    const lossy = balances.wallet < balances.placed;
    const profit = balances.wallet - balances.placed;
    const percent = getPercent(balances.placed, balances.wallet);
    return { lossy, percent, profit, ...balances };
  }
);

export default selectBalance;
