import React from 'react';
import { AiOutlineReload as ReloadIcon } from 'react-icons/ai';

const PageNotFoundComponent = React.memo(() => {
  const reloadHandler = () => {};

  return (
    <div>
      <h1>Not Found</h1>
      <button type="button" onClick={reloadHandler}>
        <ReloadIcon />
      </button>
    </div>
  );
});

PageNotFoundComponent.displayName = 'PageNotFoundComponent';

export default PageNotFoundComponent;
