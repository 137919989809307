import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';
import { formatDate, formatPrice } from '../../../core/utils';

const useStyles = createUseStyles({
  summary: ({ theme }) => ({
    background: theme.highlight,
    borderRadius: 4,
    color: theme.primary,
    overflow: 'hidden',
    textAlign: 'center',
  }),
});

const CoinSummaryComponent = React.memo(
  ({ ath, athDate, buying, currency, onClick, price }) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [showDate, setShowDate] = useState(false);

    const dateHandler = useCallback(() => {
      setShowDate(!showDate);
    }, [showDate, setShowDate]);

    const formattedAth = formatPrice(ath, currency);
    const formattedPrice = formatPrice(price, currency);
    const formattedBuying = formatPrice(buying, currency);
    const formattedDate = (athDate && formatDate(athDate)) || '-';

    return (
      <div
        className={classnames(
          classes.summary,
          'flex-columns flex-between fs12 item-center'
        )}
        role="button"
        tabIndex={-1}
        onClick={onClick}
        onMouseEnter={dateHandler}
        onMouseLeave={dateHandler}>
        <div className="flex-rows p12">
          <span className="mb6">Buying</span>
          <span className="is-bold">~&nbsp;{formattedBuying}</span>
        </div>
        <div className="flex-rows p12 fs12">
          {!showDate && (
            <React.Fragment>
              <span className="mb6">ATH</span>
              <span className="is-bold">{formattedAth}</span>
            </React.Fragment>
          )}
          {showDate && (
            <React.Fragment>
              <span className="mb6">{formattedAth}</span>
              <span className="is-bold">{formattedDate}</span>
            </React.Fragment>
          )}
        </div>
        <div className="flex-rows p12">
          <span className="mb6">Price</span>
          <span className="is-bold">{formattedPrice}</span>
        </div>
      </div>
    );
  }
);

CoinSummaryComponent.defaultProps = {
  athDate: null,
};

CoinSummaryComponent.propTypes = {
  ath: PropTypes.number.isRequired,
  athDate: PropTypes.string,
  buying: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
};

CoinSummaryComponent.displayName = 'CoinSummaryComponent';

export default CoinSummaryComponent;
