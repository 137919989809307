import classnames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import {
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
} from 'react-vis';

const useStyles = createUseStyles({
  chart: { overflowX: 'scroll' },
  xaxis: { left: 0, position: 'absolute !important', top: 0, zIndex: 10 },
});

const formatTimeMS = now => time => {
  const past = DateTime.fromMillis(time);
  const diff = now.diff(past, 'hours');
  const min = diff.toFormat('m');
  if (min < 60) return `${min} mins ago`;
  const hour = diff.toFormat('h');
  if (hour < 24) return `-${hour}H`;
  const days = diff.toFormat('d');
  return `-${days}d`;
};

const LEFT_MARGIN = 40;

const AxisXComponent = ({ data, scale, size }) => {
  const classes = useStyles();
  const scroller = useRef(null);

  useEffect(() => {
    if (scroller.current) {
      const width = scroller.current.scrollWidth;
      scroller.current.scrollLeft = width;
    }
  });

  const now = DateTime.local();
  return (
    <div
      className="is-absolute"
      style={{
        height: size.height,
        left: 0,
        top: 0,
        width: '100%',
      }}>
      <div
        ref={scroller}
        className={classnames(classes.chart, 'is-relative no-scroll-y')}
        style={{
          height: size.height,
          marginLeft: LEFT_MARGIN,
          width: `calc(100% - ${LEFT_MARGIN}px)`,
        }}>
        <XYPlot
          className={classes.xaxis}
          height={size.height}
          margin={{ bottom: 40, left: 0, right: 10, top: 10 }}
          width={size.width / scale}
          xType="linear">
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            tickFormat={formatTimeMS(now)}
            tickTotal={12 / scale}
            title="Time"
          />
          <LineSeries color="red" data={data.buying} strokeWidth={1} />
          <LineSeries color="green" data={data.rates} />
        </XYPlot>
      </div>
    </div>
  );
};

AxisXComponent.propTypes = {
  data: PropTypes.shape({
    buying: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
    rates: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
  }).isRequired,
  scale: PropTypes.number.isRequired,
  size: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

AxisXComponent.displayName = 'AxisXComponent';

export default AxisXComponent;
