import React from 'react';

import { PollerProvider } from '../../../core/contexts';
import { loadTrades } from '../../../core/services';
import Wallet from '../../components/wallet';
import Board from './board';

const TradesPageComponent = React.memo(() => (
  <PollerProvider action={loadTrades} delay={1}>
    <Wallet />
    <Board />
  </PollerProvider>
));

TradesPageComponent.displayName = 'TradesPageComponent';

export default TradesPageComponent;
