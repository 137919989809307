import PageComponent from './page';

const route = {
  Component: PageComponent,
  exact: true,
  id: PageComponent.displayName,
  path: '/charts/:coinid',
};

export default route;
