import { createCachedSelector } from 're-reselect';

const getCoinId = (_, id) => id;
const getTransactionId = (_, id) => id;
const getTransactions = _ => _.transactions;

/**
 * [selectTransaction description]
 * @type {[type]}
 */
export const selectTransaction = createCachedSelector(
  getTransactions,
  getTransactionId,
  (transactions, id) => {
    const result = transactions.find(item => item.uid === id);
    return result;
  }
)((_, id) => `transaction::${id}`);

/**
 * [selectCoinTransactions description]
 * @type {[type]}
 */
export const selectCoinTransactions = createCachedSelector(
  getTransactions,
  getCoinId,
  (transactions, id) => {
    const results = transactions.filter(item => item.coin === id);
    return results;
  }
)((_, id) => `coin::transactions::${id}`);

/**
 * [selectCoinTransactionsSummary description]
 * @type {[type]}
 */
export const selectCoinTransactionsSummary = createCachedSelector(
  getTransactions,
  getCoinId,
  (transactions, id) => {
    const results = transactions.filter(item => item.coin === id);
    return results;
  }
)((_, id, currency) => `coin::transactions::summary::${id}::${currency}`);
