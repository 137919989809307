import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { LineSeries, XYPlot, YAxis } from 'react-vis';

const useStyles = createUseStyles({
  plot: { position: 'relative !important' },
  yaxis: { left: 0, position: 'relative !important', top: 0, zIndex: 15 },
});

const formatPriceKilos = price => {
  if (price < 10000) return price;
  const rounded = Math.round(price / 100) / 10;
  return `${rounded}k`;
};

const AxisYComponent = ({ currency, data, scale, size }) => {
  const classes = useStyles();
  const scroller = useRef(null);

  useEffect(() => {
    if (scroller.current) {
      const width = scroller.current.scrollWidth;
      scroller.current.scrollLeft = width;
    }
  });

  const symbol = currency === 'eur' ? '€' : '$';
  return (
    <XYPlot
      className={classes.plot}
      height={size.height}
      width={size.width / scale}
      xType="linear">
      <YAxis
        className={classes.yaxis}
        tickFormat={formatPriceKilos}
        tickLabelAngle={-45}
        title={`Price (${symbol})`}
      />
      <LineSeries data={data.buying} opacity={0} />
      <LineSeries data={data.rates} opacity={0} />
    </XYPlot>
  );
};

AxisYComponent.defaultProps = {
  currency: '€',
};

AxisYComponent.propTypes = {
  currency: PropTypes.string,
  data: PropTypes.shape({
    buying: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
    rates: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    ),
  }).isRequired,
  scale: PropTypes.number.isRequired,
  size: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};

AxisYComponent.displayName = 'AxisYComponent';

export default AxisYComponent;
