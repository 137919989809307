import PKG from '../../package.json';

const {
  REACT_APP_MODE_DEBUG,
  REACT_APP_NOMICS_API_KEY,
  REACT_APP_TRADES_API,
} = process.env;
export const { NODE_ENV, PUBLIC_URL } = process.env;

export const KEY_CODE_ENTER = 13;
export const RESPONSIVE_TABLET_WIDTH = 768;
export const RESPONSIVE_MOBILE_WIDTH = 425;

export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const MODE_DEBUG = IS_DEVELOPMENT && REACT_APP_MODE_DEBUG === '1';

export const APP_NAME = PKG.name;
export const APP_VERSION = PKG.version;
export const EXPORT_FILE_BASENAME = `${APP_NAME}-${APP_VERSION}`;

export const NOMICS_API_BASE_KEY = 'https://api.nomics.com/v1';
export const BINANCE_BASE_URL = 'https://www.binance.com/fr/trade';
export const GECKO_API_BASE_URL = 'https://api.coingecko.com/api/v3';

export const TRADES_API = REACT_APP_TRADES_API;
export const NOMICS_API_KEY = REACT_APP_NOMICS_API_KEY;

export const CURRENCIES = ['eur', 'usd'].sort();
export const CHARTS_DAYS = ['1', '7', '14', '30', 'max'];

export const ZINDEX = {
  LOADER_SPINNER: 9999905,
  NAVIGATION: 9999900,
};

export const EVENT_TYPES = {
  ARCHIVES_ADD: 'onArchivesdAdd',
  ARCHIVES_REMOVE: 'onArchivesRemove',
  CHARTS_UPDATE: 'onChartsUpdate',
  COIN_DELETE: 'onCoinDelete',
  EXCHANGES_UPDATE: 'onExchangesUpdate',
  LOADING_COMPLETED: 'onLoadingCompleted',
  LOADING_START: 'onLoadingStart',
  MARKETS_UPDATE: 'onMarketsUpdate',
  PAGE_CHANGE: 'onPageChange',
  PAGE_RESET: 'onPageReset',
  PORTFOLIO_UPDATE: 'onPortfolioUpdate',
  PRICES_UPDATE: 'onPricesUpdate',
  SETTINGS_UPDATE: 'onSettingsUpdate',
  STATUS_UPDATED: 'onStatusUpdated',
  STORE_RESET: 'onStoreReset',
  TRADES_UPDATE: 'onTradesUpdate',
  TRANSACTION_CREATE: 'onTransactionCreate',
  TRENDINGS_UPDATE: 'onTrendingsUpdate',
};
