import classnames from 'classnames';
import React from 'react';
import { IoEllipse as StatusIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({
    '&.green': { color: theme.green },
    color: theme.red,
  }),
});

const ApiStatusCompopnent = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const status = useSelector(_ => _.status);

  return (
    <div className="flex-columns flex-center items-center is-relative fs10 mb6 mt3">
      <StatusIcon
        className={classnames(classes.icon, 'mr3', { green: status })}
      />
      <span>To the Moon!</span>
    </div>
  );
});

ApiStatusCompopnent.displayName = 'ApiStatusCompopnent';

export default ApiStatusCompopnent;
