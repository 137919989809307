import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form-hooks';
import {
  IoLogoEuro as EuroIcon,
  IoLogoUsd as DollarIcon,
  IoPieChart as VolumeIcon,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import TransactionBets from './bets';
import TransactionInput from './inputs';
import SubmitButton from './submit';

const useStyles = createUseStyles({
  button: {
    composes: ['fs8', 'is-uppercase', 'is-bold', 'mr6'],
  },
  transactionForm: ({ theme }) => ({
    background: theme.white,
  }),
});

const TransactionFormComponent = ({ onSubmit, price }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [betValue, setBetValue] = useState('');
  const { currency } = useSelector(_ => _.settings);

  const betHandler = useCallback(
    value => {
      const next = value.toString();
      setBetValue(next);
    },
    [setBetValue]
  );

  const submitHandler = useCallback(
    ({ placed: amount, volume: count }) => {
      let buying = price;
      const volume = Number(count);
      const placed = Number(amount);
      buying = placed / volume;
      onSubmit({ buying, placed, volume });
    },
    [price, onSubmit]
  );

  const validateHandler = useCallback(
    () => ({ placed, volume }) => {
      const err = {};
      if (!placed || Number(placed) === 'NaN') {
        err.placed = 'required';
      }
      if (!volume || Number(volume) === 'NaN') {
        err.volume = 'required';
      }
      return err;
    },
    []
  );

  const {
    errors,
    form,
    handleSubmit,
    hasValidationErrors,
    pristine,
    submitting,
    values,
  } = useForm({
    initialValues: { placed: betValue },
    onSubmit: submitHandler,
    validate: validateHandler,
  });

  useEffect(() => {
    form.initialize({ placed: betValue });
  }, [betValue, form]);

  const disabled = hasValidationErrors || submitting || pristine;
  const withdrawal =
    (values.placed && values.placed.indexOf('-') === 0) || false;

  return (
    <div className={classnames(classes.transactionForm, 'is-relative mt12')}>
      <TransactionBets onClick={betHandler} />
      <form onSubmit={handleSubmit}>
        <div className="flex-columns flex-between items-center px12 pb12">
          <div>
            <TransactionInput
              required
              error={!!errors?.placed}
              form={form}
              icon={currency === 'eur' ? EuroIcon : DollarIcon}
              label="Placed"
              name="placed"
            />
            <TransactionInput
              form={form}
              icon={VolumeIcon}
              label="Volume"
              name="volume"
              required={!price}
            />
          </div>
          <SubmitButton disabled={disabled} withdrawal={withdrawal} />
        </div>
      </form>
    </div>
  );
};

TransactionFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
};

TransactionFormComponent.displayName = 'TransactionFormComponent';

export default TransactionFormComponent;
