import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoCaretDown as DownIcon, IoCaretUp as UpIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../core/hooks';
import { formatPercent, formatPrice } from '../../../core/utils';

const useStyles = createUseStyles({
  percent: ({ theme }) => ({
    '&.lossy': { background: theme.red },
    background: theme.green,
    color: theme.white,
  }),
  price: ({ theme }) => ({
    color: theme.primary,
  }),
  prices: {
    maxWidth: 120,
  },
});

const CoinProfitsComponent = React.memo(
  ({ bankable, healthy, lossy, percent, placed, wallet }) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { currency, indice } = useSelector(_ => _.settings);
    const [showBankable, setShowBankable] = useState(!indice);

    useEffect(() => {
      setShowBankable(!indice);
    }, [indice]);

    return (
      <div
        className={classnames(
          classes.prices,
          'flex-rows flex-end items-end flex-0'
        )}>
        <div className="flex-columns flex-end items-center flex-0">
          <button
            className={classnames(
              classes.percent,
              'is-bold is-block text-right fs12 pill flex-columns flex-end items-center',
              { lossy }
            )}
            type="button"
            onClick={() => setShowBankable(!showBankable)}>
            <span className="mr6">
              {healthy && <UpIcon size={12} />}
              {!healthy && <DownIcon size={12} />}
            </span>
            <span>
              {!showBankable && formatPercent(percent)}
              {showBankable && formatPrice(bankable * -1, currency)}
            </span>
          </button>
        </div>
        <button
          className={classnames(classes.price, 'is-block text-right fs18 mt6', {
            'is-bold': !showBankable,
            'is-light': showBankable,
          })}
          type="button"
          onClick={() => setShowBankable(!showBankable)}>
          {!showBankable && formatPrice(wallet, currency)}
          {showBankable && formatPrice(placed, currency)}
        </button>
      </div>
    );
  }
);

CoinProfitsComponent.propTypes = {
  bankable: PropTypes.number.isRequired,
  healthy: PropTypes.bool.isRequired,
  lossy: PropTypes.bool.isRequired,
  percent: PropTypes.number.isRequired,
  placed: PropTypes.number.isRequired,
  wallet: PropTypes.number.isRequired,
};

CoinProfitsComponent.displayName = 'CoinProfitsComponent';

export default CoinProfitsComponent;
