import { IoRepeat as TradesIcon } from 'react-icons/io5';

import features from '../../../configs/features';
import PageComponent from './page';

const route = {
  Component: PageComponent,
  exact: true,
  icon: features.trades ? TradesIcon : null,
  iconSize: 24,
  id: PageComponent.displayName,
  order: 0,
  path: '/trades',
};

export default route;
