import classnames from 'classnames';
import React from 'react';
import { IoImageSharp as ImageIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';
import { formatPercent } from '../../../core/utils';
import { TradeType } from '../../../types';

const useStyles = createUseStyles({
  trade: ({ theme }) => ({
    background: theme.white,
  }),
});

const TradeCardComponent = ({ trade }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div
      className={classnames(
        classes.trade,
        'flex-columns flex-justify items-center fs12 p12'
      )}>
      <div>
        {(trade.image && (
          <img alt={trade.asset} height="auto" src={trade.image} width={16} />
        )) || <ImageIcon size={16} />}
      </div>
      <div className="is-bold">{trade.name}</div>
      <div>{trade.capital}</div>
      <div>{trade.leverage}</div>
      <div>{trade.type}</div>
      <div>{trade.status}</div>
      <div className="flex-rows flex-center items-center">
        <span>
          {trade.wins}&nbsp;/&nbsp;{trade.loss}
        </span>
        <span>{formatPercent(trade.percent)}</span>
      </div>
      <div>{trade.result}</div>
    </div>
  );
};

TradeCardComponent.propTypes = {
  trade: TradeType.isRequired,
};

TradeCardComponent.displayName = 'TradeCardComponent';

export default TradeCardComponent;
