import axios from 'axios';

import { EVENT_TYPES, GECKO_API_BASE_URL } from '../../configs/constants';

const loadPrices = (ids, currency) => {
  const url = `${GECKO_API_BASE_URL}/coins/markets`;
  return axios.get(url, {
    params: {
      ids: ids.trim(),
      order: 'market_cap_desc',
      page: 1,
      per_page: 100,
      price_change_percentage: '1h,24h,7d,14d,30d,200d,1y',
      sparkline: false,
      vs_currency: currency,
    },
  });
};

/**
 * Top-7 trending coins on CoinGecko as searched by users in the last 24 hours
 *
 */
export const loadTrendings = () => (dispatch, getState) => {
  dispatch({ type: EVENT_TYPES.LOADING_START });
  const { currency } = getState().settings;
  const url = `${GECKO_API_BASE_URL}/search/trending`;
  return axios
    .get(url, {})
    .then(({ data: { coins } }) => {
      const ids = coins
        .map(coin => coin.item)
        .map(item => item.id)
        .join(',');
      return ids;
    })
    .then(ids => loadPrices(ids, currency))
    .then(({ data: payload }) => {
      dispatch({ payload, type: EVENT_TYPES.TRENDINGS_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadTrendings;
