import get from 'lodash.get';
import { createSelector } from 'reselect';

const getTrades = _ => _.trades;
const getPortfolio = _ => _.portfolio;

export const selectTrades = createSelector(
  getTrades,
  getPortfolio,
  (trades, portfolio) => {
    const values = Object.values(portfolio);
    const results = trades
      .map(trade => {
        const coin = values.find(v => v.symbol === trade.symbol);
        const name = get(coin, 'name', trade.asset);
        const image = get(coin, 'image', null);
        const rank = get(coin, 'market_cap_rank', '-');
        return { ...trade, image, name, rank };
      })
      .map(trade => {
        const percent = (trade.wins / (trade.wins + trade.loss)) * 100;
        return { ...trade, percent };
      });
    return results;
  }
);

export default selectTrades;
