import React, { useCallback, useState } from 'react';
import { GiTwoCoins as PlacedIcon } from 'react-icons/gi';
import { IoFlame as HotIcon, IoRocket as RocketIcon } from 'react-icons/io5';
import {
  TiSortAlphabetically as AlphaIcon,
  TiSortNumerically as OrderIcon,
} from 'react-icons/ti';
import { useSelector } from 'react-redux';

import { useSorter } from '../../../core/hooks';
import { FiltersBar } from '../../components/buttons';
import CoinCard from './card';
import { selectPortfolio } from './selectors';

const FILTERS = [
  { Icon: OrderIcon, key: 'market_cap_rank', ordering: 'desc', size: 26 },
  { Icon: AlphaIcon, key: 'name', ordering: 'desc', size: 24 },
  { Icon: PlacedIcon, key: 'placed', ordering: 'asc', size: 24 },
  { Icon: HotIcon, key: 'bankable', ordering: 'desc', size: 20 },
  { Icon: RocketIcon, key: 'percent', ordering: 'asc', size: 18 },
];

const PortfolioBoardComponent = React.memo(() => {
  const portfolio = useSelector(selectPortfolio);

  const [opened, setCurrent] = useState(null);

  const { sort, sortHandler, sorted } = useSorter(portfolio, {
    desc: true,
    filter: FILTERS[4],
    toggable: true,
  });

  const toggleTransactions = useCallback(
    id => {
      const isCurrent = id === opened;
      const next = isCurrent ? null : id;
      setCurrent(next);
    },
    [opened]
  );

  return (
    <div>
      <FiltersBar filters={FILTERS} sort={sort} onChange={sortHandler} />
      <div>
        {sorted.map(coin => (
          <CoinCard
            key={coin.id}
            coin={coin}
            opened={opened === coin.id}
            onClick={toggleTransactions}
          />
        ))}
      </div>
    </div>
  );
});

PortfolioBoardComponent.displayName = 'PortfolioBoardComponent';

export default PortfolioBoardComponent;
