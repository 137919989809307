import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  BsToggleOff as ToggleOffIcon,
  BsToggleOn as ToggleOnIcon,
} from 'react-icons/bs';
import { IoMoon as MoonIcon, IoSunny as SunIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '../../../core/actions';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({ color: theme.highlight }),
  label: ({ theme }) => ({
    '&.disabled': { opacity: 0.35 },
    color: theme.highlight,
  }),
  right: { width: 80 },
});

const DarkModeToggleComponent = React.memo(({ showLabel }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const settings = useSelector(_ => _.settings);

  const settingsHandler = useCallback(() => {
    const darkmode = !settings.darkmode;
    dispatch(updateSettings({ ...settings, darkmode }));
  }, [settings, dispatch]);

  const isDark = settings.darkmode;
  const Icon = isDark ? ToggleOnIcon : ToggleOffIcon;

  return (
    <div className="flex-columns flex-between items-center">
      {showLabel && <span>Dark mode</span>}
      <div className={classes.right}>
        <button
          className="is-bold fs10 is-uppercase flex-columns flex-around items-center"
          type="button"
          onClick={settingsHandler}>
          <span className={classnames(classes.label, { disabled: isDark })}>
            <SunIcon className="fs14" />
          </span>
          <Icon className={classnames(classes.icon, 'mx12')} size={24} />
          <span className={classnames(classes.label, { disabled: !isDark })}>
            <MoonIcon className="fs14" />
          </span>
        </button>
      </div>
    </div>
  );
});

DarkModeToggleComponent.propTypes = {
  showLabel: PropTypes.bool.isRequired,
};

DarkModeToggleComponent.displayName = 'DarkModeToggleComponent';

export default DarkModeToggleComponent;
