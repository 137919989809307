import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { FeaturesConsumer } from '../../../../core/contexts';
import { useTheme } from '../../../../core/hooks';
import {
  CloseButton,
  ToggleAutoPolling,
  ToggleCurrency,
  ToggleDarkmode,
  ToggleIndice,
} from '../../buttons';
import ExportButton from './export';
import ImportButton from './import';

const useStyles = createUseStyles({
  settings: ({ theme }) => ({
    background: theme.primary,
    color: theme.white,
    height: '100%',
    width: '100%',
  }),
  toolbar: ({ theme }) => ({
    '& svg': { color: theme.white },
  }),
});

const SettingsComponent = React.memo(({ onToggle }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const version = useSelector(_ => _.version);

  return (
    <FeaturesConsumer>
      {({ features }) => (
        <div className={classnames(classes.settings, 'flex-columns')}>
          <div className="flex-rows is-full-height p12">
            <div className="mb12 flex-0 text-center">
              <span className="is-bold is-uppercase">Kryptoz</span>
              <span className="is-light fs10 ml6">v{version}</span>
            </div>
            <div className="flex-between flex-rows flex-1 is-uppercase fs12">
              <ToggleAutoPolling showLabel />
              <ToggleDarkmode showLabel />
              {features.currency_toggler && <ToggleCurrency showLabel />}
              <ToggleIndice showLabel />
            </div>
          </div>
          <div
            className={classnames(
              classes.toolbar,
              'flex-rows flex-between items-center flex-0 px6 py12 toolbar'
            )}>
            <CloseButton onClick={() => onToggle()} />
            <ImportButton />
            <ExportButton />
          </div>
        </div>
      )}
    </FeaturesConsumer>
  );
});

SettingsComponent.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

SettingsComponent.displayName = 'SettingsComponent';

export default SettingsComponent;
