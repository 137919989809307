const usdFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

const eurFormatter = new Intl.NumberFormat('fr-FR', {
  currency: 'EUR',
  style: 'currency',
});

const priceFormatters = {
  eur: eurFormatter,
  usd: usdFormatter,
  usdt: usdFormatter,
};

export const formatPrice = (price, currency) => {
  if (!price) return '-';
  const fixed = price.toFixed(2);
  const formatter = priceFormatters[currency];
  const formatted = formatter.format(fixed);
  return formatted;
};

export default formatPrice;
