import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  BsToggleOff as ToggleOffIcon,
  BsToggleOn as ToggleOnIcon,
} from 'react-icons/bs';
import {
  IoLogoEuro as EuroIcon,
  IoLogoUsd as DollarIcon,
} from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '../../../core/actions';
import { PollerConsumer } from '../../../core/contexts';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ showLabel, theme }) => ({
    color: showLabel ? theme.highlight : theme.primary,
  }),
  label: ({ showLabel, theme }) => ({
    '&.disabled': { opacity: 0.35 },
    color: showLabel ? theme.highlight : theme.primary,
  }),
  right: { width: 80 },
});

const CurrencyToggleComponent = React.memo(({ showLabel }) => {
  const theme = useTheme();
  const classes = useStyles({ showLabel, theme });
  const dispatch = useDispatch();
  const settings = useSelector(_ => _.settings);

  const settingsHandler = useCallback(
    pollerReload => {
      // toggle currency
      const currency = settings.currency === 'eur' ? 'usd' : 'eur';
      pollerReload(currency).then(() => {
        const next = { ...settings, currency };
        dispatch(updateSettings(next));
      });
    },
    [settings, dispatch]
  );

  const isDollar = settings.currency === 'usd';
  const Icon = isDollar ? ToggleOnIcon : ToggleOffIcon;

  return (
    <PollerConsumer>
      {({ reload }) => (
        <div className="flex-columns flex-between items-center">
          {showLabel && <span>Currency</span>}
          <div className={classes.right}>
            <button
              className="is-bold fs10 is-uppercase flex-columns flex-around items-center"
              type="button"
              onClick={() => settingsHandler(reload)}>
              <span
                className={classnames(classes.label, { disabled: isDollar })}>
                <EuroIcon className="fs14" />
              </span>
              <Icon className={classnames(classes.icon, 'mx12')} size={24} />
              <span
                className={classnames(classes.label, { disabled: !isDollar })}>
                <DollarIcon className="fs14" />
              </span>
            </button>
          </div>
        </div>
      )}
    </PollerConsumer>
  );
});

CurrencyToggleComponent.defaultProps = {
  showLabel: false,
};

CurrencyToggleComponent.propTypes = {
  showLabel: PropTypes.bool,
};

CurrencyToggleComponent.displayName = 'CurrencyToggleComponent';

export default CurrencyToggleComponent;
