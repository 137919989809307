import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';
import { formatDate, formatPrice } from '../../../core/utils';

const useStyles = createUseStyles({
  column: ({ theme }) => ({
    color: theme.primary,
    minWidth: 85,
    width: 85,
  }),
  price: ({ theme }) => ({
    '&.over': { color: theme.green },
  }),
});

const CoinMarketPriceComponent = ({ ath, athDate, currency, value }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showDate, setShowDate] = useState(false);

  const dateHandler = useCallback(() => {
    setShowDate(!showDate);
  }, [showDate, setShowDate]);

  const formattedDate = formatDate(athDate);
  const athFormatted = formatPrice(ath, currency);
  const priceFormatted = formatPrice(value, currency);
  const over = value > ath;

  return (
    <div
      className={classnames(
        classes.column,
        'text-right px6 py12 is-light fs12 flex-0'
      )}
      role="button"
      tabIndex={-1}
      onMouseEnter={dateHandler}
      onMouseLeave={dateHandler}>
      {!showDate && (
        <React.Fragment>
          <span className="is-block mb3 fs10">{athFormatted}</span>
          <span
            className={classnames(classes.price, 'is-block is-bold', { over })}>
            {priceFormatted}
          </span>
        </React.Fragment>
      )}
      {showDate && (
        <React.Fragment>
          <span className="is-block mb3 is-bold fs10">{formattedDate}</span>
          <span className={classnames(classes.price, 'is-block')}>
            {athFormatted}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

CoinMarketPriceComponent.propTypes = {
  ath: PropTypes.number.isRequired,
  athDate: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

CoinMarketPriceComponent.displayName = 'CoinMarketPriceComponent';

export default CoinMarketPriceComponent;
