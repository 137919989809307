import PropTypes from 'prop-types';
import React from 'react';
import { IoPieChart as ChartsIcon } from 'react-icons/io5';

const ChartsButtonComponent = React.memo(({ onClick }) => (
  <button type="button" onClick={onClick}>
    <ChartsIcon opacity={0.45} size={24} />
  </button>
));

ChartsButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

ChartsButtonComponent.displayName = 'ChartsButtonComponent';

export default ChartsButtonComponent;
