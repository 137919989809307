import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  rank: {
    minWidth: 36,
    width: 36,
  },
});

const CoinRankComponent = React.memo(({ top, value }) => {
  const classes = useStyles();
  return (
    <div
      className={classnames(
        classes.rank,
        'text-center px6 py12 fs12 is-light flex-0',
        { 'is-bold': top }
      )}>
      {value || '-'}
    </div>
  );
});

CoinRankComponent.defaultProps = {
  top: false,
  value: null,
};

CoinRankComponent.propTypes = {
  top: PropTypes.bool,
  value: PropTypes.number,
};

CoinRankComponent.displayName = 'CoinRankComponent';

export default CoinRankComponent;
