import { DateTime } from 'luxon';

const formatDate = isoDate => {
  if (!isoDate) return '-';
  const formatted = DateTime.fromISO(isoDate)
    .setLocale('fr')
    .toFormat('dd LLL yyyy')
    .toUpperCase();
  return formatted;
};

export default formatDate;
