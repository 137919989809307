import PropTypes from 'prop-types';
import React from 'react';

import FeaturesContext from './features.context';

export const FeaturesConsumer = ({ children }) => (
  <FeaturesContext.Consumer>
    {features => children(features)}
  </FeaturesContext.Consumer>
);

FeaturesConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

FeaturesConsumer.displayName = 'FeaturesConsumer';

export default FeaturesConsumer;
