import { bool, number, oneOf, shape } from 'prop-types';

export const SettingModel = {
  autopoll: bool.isRequired,
  chart_range: oneOf(['1', '7', '14', '30', 'max']).isRequired,
  currency: oneOf(['eur', 'usd']).isRequired,
  darkmode: bool.isRequired,
  indice: bool.isRequired,
  pollingms: number.isRequired,
};

export const SettingType = shape(SettingModel);
