import get from 'lodash.get';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { EVENT_TYPES } from '../../configs/constants';

export const useUploader = () => {
  const input = useRef();
  const dispatch = useDispatch();

  const errorHandler = useCallback(
    err => {
      dispatch({ type: EVENT_TYPES.LOADING_COMPLETED });
      // eslint-disable-next-line
      console.log('err', err);
    },
    [dispatch]
  );

  const loadEndHandler = useCallback(() => {
    dispatch({ type: EVENT_TYPES.LOADING_COMPLETED });
  }, [dispatch]);

  const loadStartHandler = useCallback(() => {
    dispatch({ type: EVENT_TYPES.LOADING_COMPLETED });
  }, [dispatch]);

  const onAbort = useCallback(() => {
    dispatch({ type: EVENT_TYPES.LOADING_COMPLETED });
  }, [dispatch]);

  const onLoad = useCallback(
    ({ target }) => {
      const json = get(target, 'result', null);
      const payload = JSON.parse(json);
      if (payload) {
        dispatch({ payload, type: EVENT_TYPES.STORE_RESET });
      } else {
        errorHandler(new Error('Unable to import'));
      }
    },
    [errorHandler, dispatch]
  );

  const upload = useCallback(
    evt => {
      evt.preventDefault();
      try {
        const [file] = evt.target.files;
        const reader = new FileReader();
        reader.onload = onLoad;
        reader.onabort = onAbort;
        reader.onerror = errorHandler;
        reader.onloadend = loadEndHandler;
        reader.onloadstart = loadStartHandler;
        reader.readAsText(file);
      } catch (error) {
        errorHandler(error);
      }
    },
    [errorHandler, loadEndHandler, loadStartHandler, onAbort, onLoad]
  );

  return { input, upload };
};

export default useUploader;
