import 'react-vis/dist/style.css';

import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { IoCalendarSharp as CalendarIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { CHARTS_DAYS, EVENT_TYPES } from '../../../../configs/constants';
import { PollerConsumer } from '../../../../core/contexts';
import { useTheme } from '../../../../core/hooks';

const useStyles = createUseStyles({
  button: {
    '&.active': { opacity: 1 },
    opacity: 0.35,
  },
  icon: {
    opacity: 0.35,
  },
  trigger: ({ theme }) => ({
    color: theme.primary,
  }),
});

const DaysControlComponent = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const loading = useSelector(_ => _.loading);
  const settings = useSelector(_ => _.settings);

  const daysHandler = useCallback(
    day => {
      const payload = { ...settings, chart_range: day };
      dispatch({ payload, type: EVENT_TYPES.SETTINGS_UPDATE });
    },
    [settings, dispatch]
  );

  return (
    <PollerConsumer>
      {({ reload }) => (
        <Tippy
          interactive
          content={
            <React.Fragment>
              {CHARTS_DAYS.map(day => (
                <button
                  key={`button::${day}`}
                  className={classnames(classes.button, 'fs12 mx6', {
                    active: day === settings.chart_range,
                  })}
                  type="button"
                  onClick={() => {
                    daysHandler(day);
                    reload();
                  }}>
                  {day}
                </button>
              ))}
            </React.Fragment>
          }
          disabled={loading}
          placement="bottom"
          theme="light">
          <div
            className={classnames(
              classes.trigger,
              'flex-columns flex-center items-center is-bold'
            )}>
            <CalendarIcon className={classes.icon} size={24} />
            <span className="ml6 fs12">{settings.chart_range}</span>
          </div>
        </Tippy>
      )}
    </PollerConsumer>
  );
});

DaysControlComponent.displayName = 'DaysControlComponent';

export default DaysControlComponent;
