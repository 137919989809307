import { number, shape, string } from 'prop-types';

export const CoinSchema = {
  ath: number.isRequired,
  ath_date: string.isRequired,
  currency: string.isRequired,
  current_price: string.isRequired,
  id: string.isRequired,
  image: string.isRequired,
  market_cap_rank: number.isRequired,
  name: string.isRequired,
  price_change_percentage_1h_in_currency: number.isRequired,
  symbol: string.isRequired,
};

export const CoinType = shape(CoinSchema);
