import { IoLayers as MarketsIcon } from 'react-icons/io5';

import PageComponent from './page';

const route = {
  Component: PageComponent,
  exact: true,
  icon: MarketsIcon,
  iconSize: 24,
  id: PageComponent.displayName,
  order: 3,
  path: '/markets',
};

export default route;
