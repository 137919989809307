import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { IoSync as ReloadIcon } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { PollerConsumer } from '../../../core/contexts';

const ReloadButtonComponent = React.memo(({ onClick }) => {
  const loading = useSelector(_ => _.loading);
  return (
    <PollerConsumer>
      {({ reload }) => (
        <button
          type="button"
          onClick={() => {
            if (onClick) onClick();
            else reload();
          }}>
          <ReloadIcon
            className={classnames({ loading })}
            opacity={0.45}
            size={24}
          />
        </button>
      )}
    </PollerConsumer>
  );
});

ReloadButtonComponent.defaultProps = {
  onClick: null,
};

ReloadButtonComponent.propTypes = {
  onClick: PropTypes.func,
};

ReloadButtonComponent.displayName = 'ReloadButtonComponent';

export default ReloadButtonComponent;
