import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  BsToggleOff as ToggleOffIcon,
  BsToggleOn as ToggleOnIcon,
} from 'react-icons/bs';
import {
  FaBitcoin as ProfitIcon,
  FaPercent as PercentIcon,
} from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { updateSettings } from '../../../core/actions';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({
    color: theme.highlight,
  }),
  label: ({ theme }) => ({
    '&.disabled': { opacity: 0.35 },
    color: theme.highlight,
  }),
  right: { width: 80 },
});

const IndiceToggleComponent = React.memo(({ showLabel }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const settings = useSelector(_ => _.settings);

  const toggleSettings = useCallback(() => {
    const indice = !settings.indice;
    dispatch(updateSettings({ ...settings, indice }));
  }, [settings, dispatch]);

  const isPercent = settings.indice;
  const Icon = isPercent ? ToggleOnIcon : ToggleOffIcon;

  return (
    <div className="flex-columns flex-between items-center">
      {showLabel && <span>Indice</span>}
      <div className={classes.right}>
        <button
          className="is-bold fs10 is-uppercase flex-columns flex-around items-center"
          type="button"
          onClick={() => toggleSettings()}>
          <span className={classnames(classes.label, { disabled: isPercent })}>
            <ProfitIcon className="fs14" />
          </span>
          <Icon className={classnames(classes.icon, 'mx12')} size={24} />
          <span className={classnames(classes.label, { disabled: !isPercent })}>
            <PercentIcon className="fs10" />
          </span>
        </button>
      </div>
    </div>
  );
});

IndiceToggleComponent.propTypes = {
  showLabel: PropTypes.bool.isRequired,
};

IndiceToggleComponent.displayName = 'IndiceToggleComponent';

export default IndiceToggleComponent;
