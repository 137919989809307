import PropTypes from 'prop-types';
import React from 'react';

import PollerContext from './poller.context';

const PollerConsumer = ({ children }) => (
  <PollerContext.Consumer>{state => children(state)}</PollerContext.Consumer>
);

PollerConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

PollerConsumer.displayName = 'PollerConsumer';

export default PollerConsumer;
