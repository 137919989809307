import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form-hooks';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../../core/hooks';

const useStyles = createUseStyles({
  icon: ({ theme }) => ({
    color: theme.secondary,
  }),
  input: ({ theme }) => ({
    '&.error': { border: `1px solid ${theme.red}` },
    background: theme.highlight,
    border: `1px solid ${theme.highlight}`,
    borderRadius: 8,
    color: theme.primary,
    width: 200,
  }),
  label: ({ theme }) => ({
    '&.error': { color: theme.red, fontWeight: 'bold' },
  }),
});

const TransactionFormInputComponent = ({
  error,
  form,
  icon: Icon,
  label,
  name,
  placeholder,
  required,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const field = useField(name, form);
  const hasError = field.meta.touched && error;

  return (
    <div className="flex-columns items-center flex-end mt6">
      <label
        className={classnames(
          classes.label,
          'is-block fs8 is-uppercase is-bold mb6 px6',
          { error: hasError }
        )}
        htmlFor={name}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <input
        {...field.input}
        className={classnames(
          classes.input,
          'text-right px12 py6 mr6 ml3 is-block',
          { error: hasError }
        )}
        placeholder={placeholder}
        type="number"
      />
      <Icon className={classes.icon} />
    </div>
  );
};

TransactionFormInputComponent.defaultProps = {
  error: false,
  placeholder: '',
  required: false,
};

TransactionFormInputComponent.propTypes = {
  error: PropTypes.bool,
  form: PropTypes.shape().isRequired,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

TransactionFormInputComponent.displayName = 'TransactionFormInputComponent';

export default TransactionFormInputComponent;
