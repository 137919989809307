import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';

import { FeaturesConsumer } from '../../../core/contexts';
import { useTheme } from '../../../core/hooks';
import { loadMarkets, loadSearch } from '../../../core/services';
import {
  Pagination,
  ReloadButton,
  SearchBar,
  ToggleCurrency,
} from '../../components/buttons';

const useStyles = createUseStyles({
  button: ({ theme }) => ({
    color: theme.primary,
  }),
});

const MarketsControlsComponent = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const searchHandler = useCallback(
    value => {
      dispatch(loadSearch(value, page));
    },
    [page, dispatch]
  );

  const reloadHandler = useCallback(() => {
    dispatch(loadMarkets(page));
  }, [page, dispatch]);

  const pageHandler = useCallback(
    direction => {
      const next = page + direction;
      setPage(next);
    },
    [page]
  );

  useEffect(() => {
    dispatch(loadMarkets(page));
  }, [page, dispatch]);

  return (
    <FeaturesConsumer>
      {({ features }) => (
        <React.Fragment>
          <SearchBar onChange={searchHandler} />
          <div className="controls">
            <div className={classes.button}>
              {features.currency_toggler && <ToggleCurrency />}
            </div>
            <div className={classes.button}>
              <ReloadButton onClick={reloadHandler} />
            </div>
            <div className={classes.button}>
              <Pagination hasMoreToLoad page={page} onChange={pageHandler} />
            </div>
          </div>
        </React.Fragment>
      )}
    </FeaturesConsumer>
  );
});

MarketsControlsComponent.displayName = 'MarketsControlsComponent';

export default MarketsControlsComponent;
