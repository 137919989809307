import { number, shape, string } from 'prop-types';

export const TransactionModel = {
  buying: number.isRequired,
  coin: string.isRequired,
  currency: string.isRequired,
  mtime: number.isRequired,
  placed: number.isRequired,
  status: string.isRequired,
  uid: string.isRequired,
  volume: number.isRequired,
};

export const TransactionType = shape(TransactionModel);
