import notfound from './404/routes';
import archives from './archives/routes';
import charts from './charts/routes';
import home from './home/routes';
import login from './login/routes';
import markets from './markets/routes';
import portfolio from './portfolio/routes';
import trades from './trades/routes';
import trendings from './trendings/routes';

const routes = [
  archives,
  charts,
  home,
  markets,
  login,
  portfolio,
  trades,
  trendings,
  notfound,
];

export default routes;
