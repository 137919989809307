import { useContext } from 'react';

import ThemesContext from '../contexts/themes/themes.context';

export const useTheme = () => {
  const state = useContext(ThemesContext);
  return state.theme;
};

export default useTheme;
