import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import { formatPrice } from '../../../../core/utils';

const useStyles = createUseStyles({
  pill: ({ theme }) => ({
    background: theme.highlight,
    color: theme.primary,
  }),
});

const TransactionBetComponent = ({ bets, onClick }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { currency } = useSelector(_ => _.settings);
  return (
    <div className="px12 flex-columns flex-between">
      {bets.map(bet => (
        <button
          key={bet.placed}
          className={classnames(classes.pill, 'pill fs12')}
          type="button"
          onClick={() => onClick(bet.placed)}>
          {formatPrice(bet.placed, currency)}
        </button>
      ))}
    </div>
  );
};

TransactionBetComponent.defaultProps = {
  bets: [{ placed: 50 }, { placed: 150 }, { placed: 250 }, { placed: 500 }],
};

TransactionBetComponent.propTypes = {
  bets: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func.isRequired,
};

TransactionBetComponent.displayName = 'TransactionBetComponent';

export default TransactionBetComponent;
