import omit from 'lodash.omit';

import { EVENT_TYPES } from '../../configs/constants';
import { CoinSchema } from '../../types';
import { hydrateEntities } from '../utils';

export const status = (state = false, { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.LOADING_COMPLETED:
      return !payload;
    default:
      return state;
  }
};

export const updateat = (state = Date.now(), { type }) => {
  switch (type) {
    case EVENT_TYPES.LOADING_COMPLETED:
      return Date.now();
    default:
      return state;
  }
};

export const exchanges = (state = {}, { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.EXCHANGES_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const trendings = (state = [], { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.TRENDINGS_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const archives = (state = [], { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.COIN_DELETE:
      return state;
    case EVENT_TYPES.STORE_RESET:
      return payload.archives;
    case EVENT_TYPES.ARCHIVES_ADD:
      return [...state, payload];
    case EVENT_TYPES.ARCHIVES_REMOVE:
      return state.filter(id => id !== payload);
    default:
      return state;
  }
};

export const markets = (state = [], { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.STORE_RESET:
      return [];
    case EVENT_TYPES.MARKETS_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const trades = (state = [], { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.TRADES_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const charts = (state = {}, { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.STORE_RESET:
      return {};
    case EVENT_TYPES.CHARTS_UPDATE:
      return payload;
    default:
      return state;
  }
};

export const transactions = (state = [], { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.COIN_DELETE:
      return state;
    case EVENT_TYPES.STORE_RESET:
      return payload.transactions;
    case EVENT_TYPES.TRANSACTION_CREATE:
      return [...state, payload];
    default:
      return state;
  }
};

export const portfolio = (state = {}, { payload, type }) => {
  switch (type) {
    case EVENT_TYPES.COIN_DELETE:
      return omit(state, [payload]);
    case EVENT_TYPES.PRICES_UPDATE:
    case EVENT_TYPES.MARKETS_UPDATE:
      return hydrateEntities(state, payload, CoinSchema);
    case EVENT_TYPES.STORE_RESET:
      return payload.portfolio;
    case EVENT_TYPES.PORTFOLIO_UPDATE:
      return { ...state, [payload.id]: payload };
    default:
      return state;
  }
};
