import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { IoCloseCircle as ClearIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { KEY_CODE_ENTER } from '../../../configs/constants';
import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  input: ({ theme }) => ({
    '&::placeholder': { color: theme.highlight },
    color: theme.primary,
    width: '100%',
  }),
  wrapper: ({ theme }) => ({
    background: theme.white,
    borderRadius: 25,
    height: 50,
  }),
});

const SearchComponent = ({ onChange }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const textInput = useRef(null);
  const loading = useSelector(_ => _.loading);

  const clearHandler = useCallback(() => {
    textInput.current.value = '';
    onChange('');
  }, [onChange]);

  const keyPressHandler = useCallback(
    ({ target, which }) => {
      if (which !== KEY_CODE_ENTER) return;
      const term = target.value.toLowerCase();
      onChange(term);
    },
    [onChange]
  );

  return (
    <div className="flex-columns items-center">
      <div
        className={classnames(
          'flex-columns items-center is-full-width pl24 pr12 py12 shadow',
          classes.wrapper
        )}>
        <input
          ref={textInput}
          className={classnames('flex-1 fs16', classes.input)}
          disabled={loading}
          placeholder="Rechercher"
          type="text"
          onKeyPress={keyPressHandler}
        />
        <button
          className={classnames('ml24 fs16', classes.input)}
          disabled={loading}
          style={{ opacity: 0.45, width: 32 }}
          type="button"
          onClick={clearHandler}>
          <ClearIcon size={24} />
        </button>
      </div>
    </div>
  );
};

SearchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};

SearchComponent.displayName = 'SearchComponent';

export default SearchComponent;
