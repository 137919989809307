import axios from 'axios';
import pick from 'lodash.pick';

import { EVENT_TYPES, TRADES_API } from '../../configs/constants';

const transformDataToPayload = data => {
  const pickedKeys = [
    'capital',
    'asset',
    'leverage',
    'type',
    'status',
    'wins',
    'loss',
    'result',
  ];
  const values = Object.values(data);
  const results = values.reduce((acc, trade) => {
    const picked = pick(trade, pickedKeys);
    const symbol = trade.metadata.baseAsset.toLowerCase();
    const currency = trade.metadata.quoteAsset.toLowerCase();
    const merged = { ...picked, currency, symbol };
    const next = [...acc, merged];
    return next;
  }, []);
  return results;
};

export const loadTrades = () => dispatch => {
  dispatch({ type: EVENT_TYPES.LOADING_START });
  const url = TRADES_API;
  return axios
    .get(url, {})
    .then(({ data }) => {
      const payload = transformDataToPayload(data);
      dispatch({ payload, type: EVENT_TYPES.TRADES_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadTrades;
