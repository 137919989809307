import axios from 'axios';

import { EVENT_TYPES, GECKO_API_BASE_URL } from '../../configs/constants';

const buildRequest = (currency, coinid) => {
  const chartRange = '1';
  const params = { days: chartRange, vs_currency: currency.toUpperCase() };
  const url = `${GECKO_API_BASE_URL}/coins/${coinid}/market_chart`;
  return axios.get(url, { params });
};

const reducePricesToCoins = coinid => (acc, { data }) => {
  const prices = data.prices.map(([x, y]) => ({ x, y }));
  return { ...acc, [coinid]: prices };
};

export const loadCharts = coinid => () => (dispatch, getState) => {
  dispatch({ type: EVENT_TYPES.LOADING_START });

  const { settings } = getState();
  const { currency } = settings;

  const requests = [buildRequest(currency, coinid)];

  Promise.all(requests)
    .then(datas => {
      const payload = datas.reduce(reducePricesToCoins(coinid), {});
      dispatch({ payload, type: EVENT_TYPES.CHARTS_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadCharts;
