const day = {
  background: '#FFFFFF',
  goldy: '#F3BA2E',
  green: '#02C076',
  highlight: '#EFF2F5',
  primary: '#58687E',
  red: '#CF304A',
  secondary: '#B5BCC5',
  white: '#FFFFFF',
};

const night = {
  background: '#202124',
  goldy: '#F3BA2E',
  green: '#289672',
  highlight: '#202124',
  primary: '#A4A4A4',
  red: '#E4405A',
  secondary: '#B5BCC5',
  white: '#35363A',
};

const themes = {
  day,
  night,
};

export default themes;
