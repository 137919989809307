import axios from 'axios';

import { EVENT_TYPES, GECKO_API_BASE_URL } from '../../configs/constants';

const parseSearchTerms = term =>
  term
    .trim()
    .split(' ')
    .filter(v => v.trim())
    .join(',');

export const loadSearch = (term, page, perPage) => (dispatch, getState) => {
  dispatch({ type: EVENT_TYPES.LOADING_START });

  const { settings } = getState();
  const url = `${GECKO_API_BASE_URL}/coins/markets`;
  const ids = parseSearchTerms(term);
  return axios
    .get(url, {
      params: {
        ids,
        order: 'market_cap_desc',
        page,
        per_page: perPage || 50,
        price_change_percentage: '1h,24h,7d,14d,30d,200d,1y',
        sparkline: false,
        vs_currency: settings.currency,
      },
    })
    .then(({ data: payload }) => {
      dispatch({ payload, type: EVENT_TYPES.MARKETS_UPDATE });
      dispatch({ payload: false, type: EVENT_TYPES.LOADING_COMPLETED });
    })
    .catch(() => {
      dispatch({ payload: page - 1, type: EVENT_TYPES.PAGE_CHANGE });
      dispatch({ payload: true, type: EVENT_TYPES.LOADING_COMPLETED });
    });
};

export default loadSearch;
