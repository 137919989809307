import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import { createTransaction, updatePortfolio } from '../../../core/actions';
import { useTheme } from '../../../core/hooks';
import {
  CoinHealth,
  CoinMarketPrice,
  CoinMetas,
  CoinRank,
} from '../../components/coin';
import {
  TransactionButton,
  TransactionForm,
} from '../../components/transactions';

const useStyles = createUseStyles({
  coin: ({ theme }) => ({
    '&.first': { marginTop: '0 !important' },
    '&.opened': {
      borderRadius: 8,
      marginBottom: 6,
      marginTop: 6,
      overflow: 'hidden',
    },
    background: theme.white,
  }),
});

const MarketsCoin = React.memo(
  ({ coin, first, onOpen, opened, owned, top }) => {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const dispatch = useDispatch();
    const { currency } = useSelector(_ => _.settings);

    const onCardToggle = useCallback(() => {
      const next = opened ? null : coin.id;
      onOpen(next);
    }, [opened, coin, onOpen]);

    const onTransactionSubmit = useCallback(
      values => {
        dispatch(updatePortfolio(coin));
        dispatch(createTransaction(coin, values, currency));
        onCardToggle();
      },
      [currency, coin, dispatch, onCardToggle]
    );

    return (
      <div
        className={classnames(classes.coin, 'flex-1 flex-rows flex-start', {
          first,
          opened,
        })}>
        <div className="flex-columns flex-around items-center">
          <CoinRank top={top} value={coin.market_cap_rank} />
          <CoinMetas
            id={coin.id}
            image={coin.image}
            name={coin.name}
            symbol={coin.symbol}
          />
          <CoinMarketPrice
            ath={coin.ath}
            athDate={coin.ath_date}
            currency={currency}
            value={coin.current_price}
          />
          <CoinHealth value={coin.price_change_percentage_1h_in_currency} />
          <TransactionButton
            opened={opened}
            value={owned}
            onClick={onCardToggle}
          />
        </div>
        {opened && (
          <TransactionForm
            price={coin.current_price}
            onSubmit={onTransactionSubmit}
          />
        )}
      </div>
    );
  }
);

MarketsCoin.propTypes = {
  coin: PropTypes.shape().isRequired,
  first: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  owned: PropTypes.bool.isRequired,
  top: PropTypes.bool.isRequired,
};

MarketsCoin.displayName = 'MarketsCoin';

export default MarketsCoin;
