import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IoSync as ReloadIcon } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';

import { ZINDEX } from '../../configs/constants';
import { useTheme } from '../../core/hooks';

const useStyles = createUseStyles({
  loader: ({ theme }) => ({
    '&.mounted': { top: 42 },
    background: theme.primary,
    borderRadius: '50%',
    color: theme.white,
    height: 32,
    left: '50%',
    marginLeft: -16,
    paddingLeft: 4,
    paddingTop: 4,
    top: -16,
    transition: 'top 0.3s',
    width: 32,
    zIndex: ZINDEX.LOADER_SPINNER,
  }),
});

const Loader = React.memo(() => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [mounted]);

  return (
    <div
      className={classnames('is-fixed', classes.loader, {
        mounted,
      })}>
      <ReloadIcon className="loading" size={24} />
    </div>
  );
});

Loader.displayName = 'Loader';

export default Loader;
