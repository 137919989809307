import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const bindMiddlewares = (middlewares, useReduxDevToolsExtension) => {
  const appliedMiddlewares = applyMiddleware(...middlewares);
  // TODO utiliser nappr-core/Logger
  if (useReduxDevToolsExtension) {
    const composeEnhancers = composeWithDevTools({});
    return composeEnhancers(appliedMiddlewares);
  }
  return appliedMiddlewares;
};

export default bindMiddlewares;
