import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Hint, RadialChart } from 'react-vis';

import { useTheme } from '../../../../core/hooks';
import { CloseButton } from '../../buttons';
import selectDivisions from './selectors';

const useStyles = createUseStyles({
  division: ({ theme }) => ({
    background: theme.primary,
    color: theme.white,
    height: '100%',
    width: '100%',
  }),
  toolbar: ({ theme }) => ({
    '& svg': { color: theme.white },
  }),
});

const WalletDivisionComponent = React.memo(({ onToggle }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [legend, setLegend] = useState(false);
  const divisions = useSelector(selectDivisions);

  const datas = Object.values(divisions).reduce((acc, { angle, options }) => {
    const next = [...acc, { angle, ...options }];
    return next;
  }, []);

  return (
    <div className={classnames(classes.division, 'flex-columns')}>
      <div className="flex-columns flex-center items-center">
        <RadialChart
          colorType="literal"
          data={datas}
          height={150}
          innerRadius={55}
          padAngle={0.025}
          radius={75}
          width={150}
          onSeriesMouseOut={() => setLegend(false)}
          onValueMouseOver={value => setLegend(value)}>
          {legend && (
            <Hint value={legend}>
              <div className="p6">
                <p>{legend.label}</p>
                <p>{legend.subLabel}</p>
              </div>
            </Hint>
          )}
        </RadialChart>
      </div>
      <div
        className={classnames(
          classes.toolbar,
          'flex-rows flex-between items-center flex-0 px6 py12 toolbar'
        )}>
        <CloseButton onClick={() => onToggle()} />
      </div>
    </div>
  );
});

WalletDivisionComponent.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

WalletDivisionComponent.displayName = 'WalletDivisionComponent';

export default WalletDivisionComponent;
