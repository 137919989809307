import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInterval } from '../../hooks';
import PollerContext from './poller.context';

const PollerProvider = React.memo(
  ({ action, children, delay, loader: Loader, once }) => {
    const dispatch = useDispatch();
    const loading = useSelector(_ => _.loading);
    const { autopoll, pollingms } = useSelector(_ => _.settings);

    const reload = useCallback((...params) => dispatch(action(...params)), [
      action,
      dispatch,
    ]);

    const delayms = (delay && delay * 1000) || null;
    const time = !once && autopoll && !loading ? delayms || pollingms : null;
    useInterval(reload, time);

    return (
      <PollerContext.Provider value={{ action, delay, once, reload }}>
        {children}
        {Loader && loading && <Loader />}
      </PollerContext.Provider>
    );
  }
);

PollerProvider.defaultProps = {
  delay: null,
  loader: null,
  once: false,
};

PollerProvider.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  loader: PropTypes.elementType,
  once: PropTypes.bool,
};

PollerProvider.displayName = 'PollerProvider';

export default PollerProvider;
