import { createSelector } from 'reselect';

import { convertPrice, getCumulated, getPercent } from '../../../core/utils';

const DEFAULT_VALUE = [];

const getArchives = _ => _.archives;
const getPortfolio = _ => _.portfolio;
const getExchanges = _ => _.exchanges;
const getTransactions = _ => _.transactions;
const getCurrency = _ => _.settings.currency;

const getTransactionsPlaced = (transactions, current, exchanges) => {
  const result = transactions.reduce((acc, item) => {
    const { currency, placed } = item;
    const shouldConvert = currency !== current;
    const value = shouldConvert
      ? convertPrice(placed, currency, exchanges)
      : placed;
    const next = acc + value;
    return next;
  }, 0);
  return result;
};

const getTransactionsBuying = (transactions, current, exchanges) => {
  let result = transactions.reduce((acc, item) => {
    const { buying, currency } = item;
    const shouldConvert = currency !== current;
    const value = shouldConvert
      ? convertPrice(buying, currency, exchanges)
      : buying;
    const next = acc + value;
    return next;
  }, 0);
  result /= transactions.length;
  return result;
};

export const selectPortfolio = createSelector(
  getPortfolio,
  getArchives,
  getTransactions,
  getCurrency,
  getExchanges,
  (portfolio, archives, transactions, currency, exchanges) => {
    const coins = Object.values(portfolio)
      .filter(coin => !archives.includes(coin.id))
      .map(coin => {
        // filtering transactions for current coin
        const items = transactions.filter(item => item.coin === coin.id);
        // get transactions total placed
        const placed = getTransactionsPlaced(items, currency, exchanges);
        // add buying to the coin
        const buying = getTransactionsBuying(items, currency, exchanges);
        // get transactions total volume
        const volume = getCumulated('volume', items);
        // add healthy to coin
        const healthy = coin.price_change_percentage_1h_in_currency > 0;
        // add vsAth to coin
        // add wallet to coin
        const wallet = volume * coin.current_price;
        // add lossy to coin
        const lossy = wallet < placed;
        // add profit to coin
        const profit = wallet - placed;
        // add bankable to coin
        const bankable = placed - wallet;
        // add percent to coin
        const percent = getPercent(placed, wallet);
        // then
        return {
          ...coin,
          bankable,
          buying,
          healthy,
          lossy,
          percent,
          placed,
          profit,
          volume,
          wallet,
        };
      });
    return coins || DEFAULT_VALUE;
  }
);

export default selectPortfolio;
