import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { useTheme } from '../../core/hooks';
import { loadExchanges } from '../../core/services';
import { RouteType } from '../../types';
import ApiStatus from './api-status';
import Navigation from './navigation';

const useStyles = createUseStyles({
  '@global': {
    body: {
      background: '#000000',
      color: '#ACE539',
    },
  },
  layout: ({ theme }) => ({
    background: theme.background,
    color: theme.primary,
  }),
});

const MainLayoutComponent = React.memo(({ routes }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const mounted = useRef(false);
  const dispatch = useDispatch();
  const appready = useSelector(_ => _.appready);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      dispatch(loadExchanges());
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <div
        className={classnames(
          classes.layout,
          'is-relative is-full-height pb78 pt6 px12'
        )}
        id="application-container">
        <ApiStatus />
        <div id="application-page">
          <Switch>
            {routes.map(page => (
              <Route
                key={page.id}
                exact={page.exact}
                path={page.path}
                render={routeProps => (
                  <page.Component appready={appready} {...routeProps} />
                )}
              />
            ))}
          </Switch>
        </div>
      </div>
      <Navigation routes={routes} />
    </React.Fragment>
  );
});

MainLayoutComponent.propTypes = {
  routes: PropTypes.arrayOf(RouteType).isRequired,
};

MainLayoutComponent.displayName = 'MainLayoutComponent';

export default MainLayoutComponent;
