import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import bindMiddlewares from './bind-middlewares';
import getStorageConfig from './get-storage-config';

const configure = (states, reducers, middlewares = [], debug = false) => {
  const storageConfig = getStorageConfig(states);
  const createRootReducers = combineReducers(reducers);
  const initialState = { ...states.blacklist, ...states.whitelist };

  const store = createStore(
    persistReducer(storageConfig, createRootReducers),
    initialState,
    bindMiddlewares(middlewares, debug)
  );

  const persistor = persistStore(store, null, null);
  return { persistor, store };
};

export default configure;
