import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../../core/hooks';
import { selectCoinTransactions } from '../../../../core/selectors';
import TransactionItem from './transaction';
// import TableControls from './controls';
import TransactionVolume from './volume';

const useStyles = createUseStyles({
  tbody: ({ theme }) => ({
    border: `1px solid ${theme.highlight}`,
    borderRadius: '0 0 8px 8px',
    color: theme.primary,
    height: 120,
    maxHeight: 120,
    minHeight: 120,
    overflowX: 'hidden',
    overflowY: 'scroll',
  }),
});

const TransactionsTableComponent = ({ coinId }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const transactions = useSelector(_ => selectCoinTransactions(_, coinId));

  return (
    <div className="is-relative mt12">
      <TransactionVolume coinId={coinId} />
      <div className={classnames(classes.tbody)}>
        {transactions.map((transaction, index) => (
          <TransactionItem
            key={transaction.uid}
            odd={!!(index % 2)}
            transaction={transaction}
          />
        ))}
      </div>
      {/* <TableControls coinId={coinId} /> */}
    </div>
  );
};

TransactionsTableComponent.propTypes = {
  coinId: PropTypes.string.isRequired,
};

TransactionsTableComponent.displayName = 'TransactionsTableComponent';

export default TransactionsTableComponent;
