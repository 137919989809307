import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { PollerProvider } from '../../../core/contexts';
import { loadCharts } from '../../../core/services';
import Loader from '../../components/loader';
import Wallet from '../../components/wallet';
import Board from './board';

const ChartsPageComponent = React.memo(() => {
  const history = useHistory();
  const { coinid } = useParams();
  const shouldRedirect = !history.location.state;
  return (
    (shouldRedirect && <Redirect to="/" />) || (
      <PollerProvider action={loadCharts(coinid)} delay={60} loader={Loader}>
        <Wallet />
        <Board coinid={coinid} />
      </PollerProvider>
    )
  );
});

ChartsPageComponent.displayName = 'ChartsPageComponent';

export default ChartsPageComponent;
