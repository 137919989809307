import classnames from 'classnames';
import React from 'react';
import {
  FaBalanceScale as StatusIcon,
  FaPercent as PercentIcon,
} from 'react-icons/fa';
import { TiSortAlphabetically as AlphaIcon } from 'react-icons/ti';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useSorter } from '../../../core/hooks';
import { FiltersBar } from '../../components/buttons';
import Card from './card';
import { selectTrades } from './selectors';

const useStyles = createUseStyles({
  board: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

const FILTERS = [
  { Icon: AlphaIcon, key: 'name', ordering: 'desc', size: 24 },
  { Icon: StatusIcon, key: 'status', ordering: 'desc', size: 17 },
  { Icon: PercentIcon, key: 'percent', ordering: 'desc', size: 13 },
];

const TradesBoardComponent = () => {
  const classes = useStyles();
  const trades = useSelector(selectTrades);

  const { sort, sortHandler, sorted } = useSorter(trades, {
    desc: false,
    filter: FILTERS[0],
    toggable: true,
  });

  return (
    <div>
      <FiltersBar filters={FILTERS} sort={sort} onChange={sortHandler} />
      <div className={classnames(classes.board, 'is-relative shadow')}>
        {sorted.map(trade => (
          <Card key={trade.asset} trade={trade} />
        ))}
      </div>
    </div>
  );
};

TradesBoardComponent.displayName = 'TradesBoardComponent';

export default TradesBoardComponent;
