import pick from 'lodash.pick';

import { EVENT_TYPES } from '../../configs/constants';
import { CoinSchema } from '../../types';

export const updatePortfolio = coin => {
  const keys = Object.keys(CoinSchema);
  const values = pick(coin, keys);
  const payload = { ...values, current_price: 0 };
  return {
    payload,
    type: EVENT_TYPES.PORTFOLIO_UPDATE,
  };
};

export default updatePortfolio;
