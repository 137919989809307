import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { NavLink } from 'react-router-dom';

import { ZINDEX } from '../../configs/constants';
import { useTheme } from '../../core/hooks';
import { RouteType } from '../../types';

const useStyles = createUseStyles({
  button: ({ theme }) => ({
    '&.active': { opacity: 1 },
    color: theme.primary,
    opacity: 0.35,
    transition: 'opacity 0.5s',
  }),
  container: ({ theme }) => ({
    background: theme.white,
    borderRadius: '16px 16px 0 0',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: ZINDEX.NAVIGATION,
  }),
});

const NavigationComponent = React.memo(({ routes }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div
      className={classnames(
        classes.container,
        'is-absolute flex-columns flex-around shadow py12 items-center'
      )}
      id="navigation">
      {routes
        .filter(v => !isEmpty(v))
        .filter(route => !!route.icon)
        .sort((a, b) => a.order - b.order)
        .map(route => (
          <NavLink key={route.id} className={classes.button} to={route.path}>
            <route.icon size={route.iconSize} />
          </NavLink>
        ))}
    </div>
  );
});

NavigationComponent.propTypes = {
  routes: PropTypes.arrayOf(RouteType).isRequired,
};

NavigationComponent.displayName = 'NavigationComponent';

export default NavigationComponent;
