import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { useTheme } from '../../../core/hooks';
import {
  CoinMetas,
  CoinProfits,
  CoinProfitsClosed,
  CoinSummary,
} from '../../components/coin';
import TransactionsTable from '../../components/transactions/transactions-table';

const useStyles = createUseStyles({
  card: ({ theme }) => ({
    background: theme.white,
  }),
});

const CoinCardComponent = React.memo(({ coin, onClick, opened }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { currency } = useSelector(_ => _.settings);

  const onSummaryClick = useCallback(() => {
    onClick(coin.id);
  }, [coin.id, onClick]);

  const isClosed = Math.floor(coin.placed) === 0;

  return (
    <div className={classnames(classes.card, 'card pt24')}>
      <div className="is-relative" style={{ height: 80 }}>
        <div className="is-absolute flex-columns is-trbl items-center flex-between mb24">
          <CoinMetas
            large
            id={coin.id}
            image={coin.image}
            name={coin.name}
            rank={coin.market_cap_rank}
            symbol={coin.symbol}
          />
          {(!isClosed && (
            <CoinProfits
              bankable={coin.bankable}
              healthy={coin.healthy}
              lossy={coin.lossy}
              percent={coin.percent}
              placed={coin.placed}
              wallet={coin.wallet}
            />
          )) || (
            <CoinProfitsClosed
              bankable={coin.bankable}
              healthy={coin.healthy}
              wallet={coin.wallet}
            />
          )}
        </div>
      </div>
      <CoinSummary
        ath={coin.ath}
        athDate={coin.ath_date}
        buying={coin.buying}
        currency={currency}
        price={coin.current_price}
        onClick={onSummaryClick}
      />
      {opened && <TransactionsTable coinId={coin.id} />}
    </div>
  );
});

CoinCardComponent.propTypes = {
  coin: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
};

CoinCardComponent.displayName = 'CoinCardComponent';

export default CoinCardComponent;
