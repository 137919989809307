import React from 'react';

const ProfitCalculatorComponent = React.memo(() => {
  const result = 1;
  return <div>{result}</div>;
});

ProfitCalculatorComponent.propTypes = {};

ProfitCalculatorComponent.displayName = 'ProfitCalculatorComponent';

export default ProfitCalculatorComponent;
