import React from 'react';
import { IoCloudUpload as ImportIcon } from 'react-icons/io5';

import { useUploader } from '../../../../core/hooks';

const ImportButtonComponent = React.memo(() => {
  const { input, upload } = useUploader();
  return (
    <React.Fragment>
      <label htmlFor="uploader">
        <ImportIcon size={24} />
      </label>
      <input
        ref={input}
        accept="application/json"
        className="is-hidden"
        id="uploader"
        name="uploader"
        type="file"
        onChange={upload}
      />
    </React.Fragment>
  );
});

ImportButtonComponent.displayName = 'ImportButtonComponent';

export default ImportButtonComponent;
