import React from 'react';

import { PollerProvider } from '../../../core/contexts';
import { loadPrices } from '../../../core/services';
import Loader from '../../components/loader';
import Wallet from '../../components/wallet';
import Board from './board';

const PortfolioPageComponent = () => (
  <PollerProvider action={loadPrices} loader={Loader}>
    <Wallet />
    <Board />
  </PollerProvider>
);
PortfolioPageComponent.displayName = 'PortfolioPageComponent';

export default PortfolioPageComponent;
