import PropTypes from 'prop-types';
import React from 'react';

import { CoinMetas } from '../../../components/coin';
// import ValuesColumn from './values';

const SummaryComponent = ({ coin }) => (
  <div className="is-relative" style={{ height: 80 }}>
    <div className="is-absolute flex-columns is-trbl items-center flex-between mb24">
      <CoinMetas
        large
        id={coin.id}
        image={coin.image}
        name={coin.name}
        rank={coin.rank}
        symbol={coin.symbol}
      />
      {/* <ValuesColumn
        bankable={coin.bankable}
        healthy={coin.healthy}
        lossy={coin.lossy}
        percent={coin.percent}
        placed={coin.placed}
        wallet={coin.wallet}
      /> */}
    </div>
  </div>
);

SummaryComponent.propTypes = {
  coin: PropTypes.shape().isRequired,
};

SummaryComponent.displayName = 'SummaryComponent';

export default SummaryComponent;
