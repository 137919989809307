import { element, number, shape, string } from 'prop-types';

export const FilterSchema = {
  Icon: element.isRequired,
  order: string.isRequired,
  size: number.isRequired,
  type: string.isRequired,
};

export const FilterType = shape(FilterSchema);
