import { createSelector } from 'reselect';

import { convertPrice, formatPercent } from '../../../../core/utils';

const getArchives = _ => _.archives;
const getPortfolio = _ => _.portfolio;
const getExchanges = _ => _.exchanges;
const getTransactions = _ => _.transactions;
const getCurrency = _ => _.settings.currency;

const getTransactionsPlaced = (transactions, current, exchanges) => {
  const result = transactions.reduce((acc, item) => {
    const { currency, placed } = item;
    const shouldConvert = currency !== current;
    const value = shouldConvert
      ? convertPrice(placed, currency, exchanges)
      : placed;
    const next = acc + value;
    return next;
  }, 0);
  return result;
};

const selectDivisions = createSelector(
  getPortfolio,
  getArchives,
  getTransactions,
  getCurrency,
  getExchanges,
  (portfolio, archives, transactions, currency, exchanges) => {
    const balances = Object.keys(portfolio)
      // filter archives
      .filter(coinid => !archives.includes(coinid))
      .map(coinid => portfolio[coinid])
      .reduce((acc, coin) => {
        // filter transactions for a coin
        const items = transactions.filter(item => item.coin === coin.id);
        // get transactions total placed
        const placed = getTransactionsPlaced(items, currency, exchanges);
        return { ...acc, [coin.id]: placed };
      }, {});

    const overall = Object.values(balances).reduce((acc, value) => {
      const next = acc + value;
      return next;
    }, 0);

    const percents = Object.entries(balances).reduce((acc, [key, value]) => {
      const percent = (value * 100) / overall;
      const angle = (360 * percent) / 100;
      const label = portfolio[key].name;
      const subLabel = `${formatPercent(percent, false)}%`;
      const options = { label, subLabel };
      return { ...acc, [key]: { angle, options, percent } };
    }, {});

    return percents;
  }
);

export default selectDivisions;
