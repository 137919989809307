import { createCachedSelector } from 're-reselect';

import { getCumulated } from '../../../core/utils';

const getPortfolio = _ => _.portfolio;
const getTransactions = _ => _.transactions;
const getCoinId = (_, coinid) => coinid;
const getCharts = _ => _.charts;

const getCumulatedValue = (acc, transaction, index) => ({
  buying: acc.buying + transaction.buying,
  length: index + 1,
  volume: acc.volume + transaction.volume,
});

export const selectCoin = createCachedSelector(
  getPortfolio,
  getTransactions,
  getCoinId,
  (portfolio, transactions, coinid) => {
    const coin = portfolio[coinid];
    const items = transactions.filter(item => item.coin === coin.id);
    const buying = getCumulated('buying', items);
    const volume = getCumulated('volume', items);
    const next = { ...coin, buying, volume };
    return next;
  }
)((_, coinid) => `coin::${coinid}`);

export const selectChart = createCachedSelector(
  getCharts,
  getPortfolio,
  getTransactions,
  getCoinId,
  (charts, portfolio, transactions, coinid) => {
    const rates = charts[coinid];
    if (!rates) return { buying: [], rates: [] };

    const extras = transactions
      .filter(transaction => transaction.coin === coinid)
      .reduce(getCumulatedValue, { buying: 0, length: 0, volume: 0 });

    const buying = rates.map(({ x }) => ({
      x,
      y: extras.buying / extras.length,
    }));

    const results = { buying, rates };
    return results;
  }
)((_, coinid) => `coin::chart::${coinid}`);
