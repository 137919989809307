import React from 'react';

const initialState = {
  action: null,
  delay: null,
  once: false,
  reload: null,
};

const PollerContext = React.createContext(initialState);

PollerContext.displayName = 'PollerContext';

export default PollerContext;
