import { useCallback } from 'react';

import { EXPORT_FILE_BASENAME } from '../../configs/constants';

export const useDownloader = data => {
  const now = Date.now();
  const filename = `${EXPORT_FILE_BASENAME}-${now}.json`;

  const download = useCallback(() => {
    const json = JSON.stringify(data, null, 2);
    const elt = document.createElement('a');
    const file = new Blob([json], { type: 'application/json' });
    elt.href = URL.createObjectURL(file);
    elt.download = filename;
    document.body.appendChild(elt);
    elt.click();
    elt.remove();
  }, [data, filename]);

  return { download };
};

export default useDownloader;
