import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IoIosArrowRoundDown as ArrowDown,
  IoIosArrowRoundUp as ArrowUp,
} from 'react-icons/io';
import { createUseStyles } from 'react-jss';

import { useTheme } from '../../../core/hooks';

const useStyles = createUseStyles({
  button: ({ theme }) => ({
    '&.active': { opacity: 1 },
    color: theme.primary,
    opacity: 0.35,
  }),
});

const FiltersBarComponent = React.memo(({ filters, onChange, sort }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className="controls">
      {filters.map(filter => {
        const active = sort.key === filter.key;
        const ArrowIcon = !sort.desc ? ArrowUp : ArrowDown;
        return (
          <button
            key={filter.key}
            className={classnames(
              classes.button,
              'flex-columns flex-center items-center text-center',
              { active }
            )}
            style={{ flexBasis: 0, flexGrow: 1 }}
            type="button"
            onClick={() => onChange(filter)}>
            <filter.Icon size={filter.size} />
            <ArrowIcon size={16} style={{ opacity: Number(active) }} />
          </button>
        );
      })}
    </div>
  );
});

FiltersBarComponent.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    desc: PropTypes.bool.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
};

FiltersBarComponent.displayName = 'FiltersBarComponent';

export default FiltersBarComponent;
