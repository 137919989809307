import classnames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({
  countdown: {
    height: 24,
    width: 24,
  },
  svg: {
    height: 24,
    opacity: 0.45,
    right: 0,
    top: 0,
    transform: 'rotateY(-180deg) rotateZ(-90deg)',
    width: 24,
  },
});

const PollingButtonComponent = React.memo(() => {
  const classes = useStyles();
  const loading = useSelector(_ => _.loading);
  const { pollingms } = useSelector(_ => _.settings);

  return (
    <div
      className={classnames(
        classes.countdown,
        'is-relative text-center countdown'
      )}>
      <svg
        className={classnames(classes.svg, 'is-absolute')}
        fill="currentColor"
        stroke="currentColor">
        <circle
          cx={12}
          cy={12}
          r={8}
          style={{
            animationDelay: 0,
            animationDirection: 'forwards',
            animationDuration: `${pollingms}ms`,
            animationIterationCount: 1,
            animationName: (loading && 'none') || 'countdown',
            animationTimingFunction: 'linear',
          }}
        />
      </svg>
    </div>
  );
});

PollingButtonComponent.displayName = 'PollingButtonComponent';

export default PollingButtonComponent;
