import React from 'react';
import { IoSave as ImportIcon } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { useDownloader } from '../../../../core/hooks';

const ExportButtonComponent = React.memo(() => {
  const version = useSelector(_ => _.version);
  const archives = useSelector(_ => _.archives);
  const settings = useSelector(_ => _.settings);
  const portfolio = useSelector(_ => _.portfolio);
  const transactions = useSelector(_ => _.transactions);

  const { download } = useDownloader({
    archives,
    portfolio,
    settings,
    transactions,
    version,
  });

  return (
    <button type="button" onClick={download}>
      <ImportIcon size={24} />
    </button>
  );
});

ExportButtonComponent.displayName = 'ExportButtonComponent';

export default ExportButtonComponent;
